import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import palette from '../../../../theme/palette';

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            position: 'fixed',
            width: '700px',
            top: 0,
            left: 0,
        },
        viewBox: {
            width: '500px',
            minHeight: '700px',
            overflow: 'hidden',
        },
        root: {
            bottom: '10%',
            right: '10%',
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderWidth: '200px',
            borderStyle: 'solid',
            borderRadius: '50%',
            zIndex: -1,
            opacity: '0.4',
        },
    }),
);

const Arc: React.FC<{
    color?: string;
}> = ({ color }) => {
    const classes = useStyles();

    return (
        <div className={classes.box}>
            <div className={classes.viewBox}>
                <div
                    className={classes.root}
                    style={{ borderColor: color ?? palette.light.shape }} />
            </div>
        </div>
    );
};

export default Arc;
