import { getStatsigUserId } from '@experiences/ab-testing';
import { isbot } from 'isbot';
import type { StatsigUser } from 'statsig-react';

import {
    getEnvironmentConfig,
    isLocal,
} from './EnvironmentUtil';

export function getFeatureFlagOverrides() {
    return getEnvironmentConfig().FEATURE_FLAG_OVERRIDES as { [key: string]: boolean };
}

export function getStatsigClientKey() {
    return getEnvironmentConfig().STATSIG_SDK_CLIENT_KEY as string;
}

export function getStatsigUser() {
    const userAgent = window.navigator.userAgent;

    const isBot = isbot(userAgent);

    // intentionally pass no user here, so that Statsig will generate a stable ID
    const user: StatsigUser = {
        userID: getStatsigUserId(),
        userAgent,
        custom: { isBot },
    };

    return user;
}

export function getOrganizationAppOriginFromEnv({
    useLocal = false, useServer = false, useGlobal = false,
} = {}) {
    const config = getEnvironmentConfig();

    if (useLocal || (isLocal() && (useServer || useGlobal))) {
        return useServer ? config.SERVER_ORIGIN
            : useGlobal ?
                config.GLOBAL_ORIGIN : config.PORTAL_ORIGIN;
    }

    return config.PORTAL_ORIGIN as string;
}

