import { UiText } from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type {
    PropsWithChildren,
    ReactNode,
} from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

import useLogin from '../../util/hooks/Login';

const useStyles = makeStyles(theme =>
    createStyles({
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            color: theme.palette.common.black,
            fontFamily: 'Poppins',
            fontWeight: 600,
            textAlign: 'center',
            fontSize: '24px',
        },
        standardMargin: {
            margin: '16px 0',
            textAlign: 'center',
        },
    }),
);

interface IUiSignupContainerProps {
    title?: string;
    centered?: boolean;
    customTitle?: ReactNode;
    customTitleText?: string;
}

export const UiSignupContainer = ({
    children, title = 'CREATE_ACCOUNT', centered = false, customTitle, customTitleText,
}: PropsWithChildren<IUiSignupContainerProps>) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const navigateToLogin = useLogin();

    return (
        <div style={{
            marginTop: centered ? 'auto' : '60px',
            width: '400px',
        }}>
            <div className={classes.flex}>
                {customTitle
                    ? customTitle
                    : <UiText
                        data-cy="ui-signup-title"
                        className={classes.title}>
                        {translate({ id: customTitleText ? customTitleText : title })}
                    </UiText>}
                <UiText className={classes.standardMargin}>
                    {translate(
                        { id: 'ACCOUNT_ALREADY_EXISTS_SKU' },
                        {
                            SIGNIN: (
                                <Link
                                    onClick={() => {
                                        navigateToLogin();
                                    }}
                                    data-cy="signup-goto-login"
                                    tabIndex={0}
                                    style={{ cursor: 'pointer' }}
                                    underline="none"
                                >
                                    {translate({ id: 'SIGN_IN' })}
                                </Link>
                            ),
                        },
                    )}
                </UiText>
                {children}
            </div>
        </div>
    );
};
