import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import React, { useMemo } from 'react';
import type {
    Control,
    FieldErrors,
    FieldPath,
    FieldValues,
} from 'react-hook-form';
import { Controller } from 'react-hook-form';

import UiActionErrorMessage from '../UiActionErrorMessage/UiActionErrorMessage';
import { AuthErrorMap } from '../UiSignup/AuthError';

type IUiEmailProps<T extends FieldValues> = Omit<TextFieldProps, 'onChange' | 'onFocus' | 'onBlur'> & {
    control: Control<T>;
    name: FieldPath<T>;
    errors?: FieldErrors<T>;
};

export const UiEmail = <T extends FieldValues>(props: IUiEmailProps<T>) => {
    const {
        control, name, errors, ...rest
    } = props;

    const error = useMemo(() => {
        const key = errors?.email?.type as string;
        if (key in AuthErrorMap) {
            return AuthErrorMap[key];
        }
        return undefined;
    }, [ errors?.email?.type ]);

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: true,
                minLength: 1,
                maxLength: 256,
                pattern: /^([a-zA-Z0-9_.\-+'])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,4})+$/,
            }}
            render={({ field }) =>
                <TextField
                    {...field}
                    required
                    variant="outlined"
                    helperText={error ? <UiActionErrorMessage error={error} /> : null}
                    error={!!errors?.email}
                    {...rest}
                />}
        />
    );
};
