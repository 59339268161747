import Cookies from 'js-cookie';
import {
    useCallback,
    useMemo,
} from 'react';

import { useParams } from '../../container/Router';
import Auth from '../AuthUtil';
import {
    Features,
    useFeatureFlagValue,
} from '../FeatureFlagUtil';
import Telemetry from '../TelemetryUtil';
import { PlatformName } from './SignupType';

export default function useAuthInstance() {
    const { platform_name } = useParams();

    const EnableCommunitySignUpText = useFeatureFlagValue(Features.EnableCommunitySignUpText.name);
    const EnableFreeSignUpText = useFeatureFlagValue(Features.EnableFreeSignUpText.name);

    const signUpWithText = useMemo(() => {
        switch (platform_name) {
            case PlatformName.UiPlatform:
            case PlatformName.UiAutomationCloud:
            case PlatformName.UiCommunity:
                return EnableCommunitySignUpText;
            case PlatformName.UiEnterprise:
                return true;
            case PlatformName.UiFree :
                return EnableFreeSignUpText;
            default:
                return false;
        }
    }, [ platform_name, EnableCommunitySignUpText, EnableFreeSignUpText ]);

    const getAuthInstance = useCallback(() => {
        Telemetry.setTelemetryOptions({
            GAId: Cookies.get('_ga'),
            platformName: platform_name,
            signUpWithText,
        });

        return Auth.getInstance();
    }, [ platform_name, signUpWithText ]);

    return getAuthInstance;
}
