import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import palette from '../../../../theme/palette';

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 4,
            position: 'relative',
            overflow: 'visible',
            width: '100%',
            minWidth: '700px',
        },
        root: {
            width: '192px',
            minHeight: '192px',
            borderRadius: '96px',
            borderStyle: 'none',
            position: 'absolute',
            bottom: '80%',
            right: '0px',
            zIndex: -1,
            opacity: '0.4',
        },
    }),
);

const Circle: React.FC<{
    color?: string;
}> = ({ color }) => {
    const classes = useStyles();

    return (
        <div className={classes.box}>
            <div
                className={classes.root}
                style={{ background: color ?? palette.light.shape }} />
        </div>
    );
};

export default Circle;
