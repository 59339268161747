import { UiText } from '@experiences/ui-common';
import type { ButtonTypeMap } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { ExtendButtonBase } from '@mui/material/ButtonBase';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            flex: 1,
            pointerEvents: 'none',
            float: 'left',
            width: '70%',
        },
        content: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            height: '18px',
            width: '18px',
            padding: '2px 15px 4px 0px',
            marginRight: '15px',
            borderRight: '1px solid #ddd',
        },
        socialButtonText: {
            fontSize: '14px',
            fontWeight: 600,
        },
    }),
);

export const UiSocialButton: ExtendButtonBase<ButtonTypeMap<{ icon?: JSX.Element; centered?: boolean; textColor?: string }>> = (
    props: any,
) => {
    const classes = useStyles();
    const theme = useTheme();
    const {
        icon, centered, textColor, children, ...rest
    } = props;

    return (
        <Button
            {...rest}
            variant="outlined"
            size="large"
            startIcon={centered ? icon : undefined}>
            {centered ? (
                <UiText
                    className={classes.socialButtonText}
                    color={textColor ?? theme.palette.semantic.colorForegroundDeEmp}>
                    {children}
                </UiText>
            ) : (
                <div className={classes.buttonContainer}>
                    <div className={classes.content}>
                        <div className={classes.icon}>
                            {icon}
                        </div>
                        <UiText
                            className={classes.socialButtonText}
                            color={textColor ?? theme.palette.semantic.colorForegroundDeEmp}>
                            {children}
                        </UiText>
                    </div>
                </div>
            )}
        </Button>
    );
};
