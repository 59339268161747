import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import { DirectBuyOrderSummaryComponent } from '../../../common/UiDirectBuy';
import {
    SignupOptionsComponent,
    UiSignupContainer,
} from '../../../common/UiSignup';
import { useParams } from '../../../container/Router';
import { buildDirectBuyData } from '../../../util/ECommerceUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        trademark: {
            color: theme.palette.semantic.colorForeground,
            fontWeight: 400,
            fontSize: '8px',
            position: 'relative',
            top: '-0.4em',
        },
        directBuyTitle: {
            fontSize: '20px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
            margin: '32px 0 22px 0',
        },
    }),
);

const DirectBuyContainer = () => {
    const classes = useStyles();
    const params = useParams();
    const { formatMessage: translate } = useIntl();

    const renderTradeMark = useCallback((chunks: React.ReactNode[]) => <sup className={classes.trademark}>
        {chunks}
    </sup>, [ classes.trademark ]);

    const getTitle = () => <UiText
        align="center"
        data-cy="direct-buy-preview-title"
        className={classes.directBuyTitle}>
        {translate({ id: 'GET_STARTED_FOR_DIRECT_BUY' }, { tm: renderTradeMark })}
    </UiText>;

    const directBuyData = useMemo(() => buildDirectBuyData(params), [ params ]);

    return (
        <UiSignupContainer
            customTitle={getTitle()}>
            <DirectBuyOrderSummaryComponent
                directBuyData={directBuyData} />
            <SignupOptionsComponent />
        </UiSignupContainer>
    );
};

export default DirectBuyContainer;
