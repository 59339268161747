import { UiText } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { TextFieldClassName } from '@uipath/portal-shell-util';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { getOrganizationAppOriginFromEnv } from '../../../util/ConfigUtil';
import {
    getLastUsedLoginEnterpriseSsoOrg,
    setCurrentLoginMethod,
    setLastUsedLoginEnterpriseSsoOrg,
} from '../../../util/CookieUtil';
import useLogin from '../../../util/hooks/Login';
import Providers from '../../../util/interfaces/provider';
import { redirectToSsoRetryUrl } from './EnterpriseSsoLoginHelper';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: '20px',
            fontWeight: 600,
            margin: '32px 0',
        },
        label: {
            fontSize: '15px',
            fontWeight: 600,
            marginBottom: '8px',
        },
        container: { width: '100%' },
        orgField: { marginBottom: '24px' },
        defaultFontSize: { fontSize: '14px' },
        link: {
            fontSize: '14px',
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        backToLogin: { margin: '12px 0' },
    }),
);

interface IEnterpriseSsoForm {
    organization: string;
}

const EnterpriseSsoLoginComponent: React.FC<{ retryUrl: string }> = ({ retryUrl }) => {
    const classes = useStyles();
    const navigateToLogin = useLogin();
    const { formatMessage: translate } = useIntl();

    const portalUrl = useMemo(() => getOrganizationAppOriginFromEnv(), []);

    const {
        register, handleSubmit, formState: { errors },
    } = useForm<IEnterpriseSsoForm>({ defaultValues: { organization: getLastUsedLoginEnterpriseSsoOrg() ?? '' } });

    const onSubmit = useCallback(
        ({ organization }: IEnterpriseSsoForm) => {
            setCurrentLoginMethod(Providers.EnterpriseSSO);
            setLastUsedLoginEnterpriseSsoOrg(organization);
            redirectToSsoRetryUrl(portalUrl, retryUrl, organization);
        },
        [ portalUrl, retryUrl ],
    );

    return (
        <>
            <UiText
                className={classes.title}
                role="heading"
                aria-level={1}>
                {translate({ id: 'SIGN_IN_WITH_ENTERPRISE_SSO' })}
            </UiText>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.container}>
                <div className={classes.container}>
                    <UiText className={classes.label}>
                        {translate({ id: 'ORGANIZATION_URL' })}
                    </UiText>
                    <TextField
                        className={clsx(TextFieldClassName.OmitStartAdornmentSpacing, classes.orgField)}
                        variant="outlined"
                        fullWidth
                        placeholder={translate({ id: 'ORGANIZATION' })}
                        error={!!errors.organization}
                        helperText={errors.organization?.message}
                        inputProps={{
                            'data-cy': 'enterprise-sso-org-input',
                            ...register('organization', {
                                minLength: 1,
                                maxLength: {
                                    value: 64,
                                    message: translate({ id: 'INVALID_ORGANIZATION_NAME' }),
                                },
                                required: {
                                    value: true,
                                    message: translate({ id: 'REQUIRED' }),
                                },
                                pattern: {
                                    value: /^[\w]+$/,
                                    message: translate({ id: 'INVALID_ORGANIZATION_NAME' }),
                                },
                            }),
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {portalUrl + '/'}
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    data-cy="enterprise-sso-continue-button"
                >
                    {translate({ id: 'CONTINUE' })}
                </Button>
            </form>
            <div className={classes.backToLogin}>
                <UiText
                    display="inline"
                    className={classes.defaultFontSize}>
                    {translate({ id: 'BACK_TO' })}
                </UiText>
                {' '}
                <UiText
                    display="inline"
                    className={classes.link}
                    onClick={() => navigateToLogin()}
                    data-cy="forgot-password-goto-login"
                    tabIndex={0}>
                    {translate({ id: 'SIGN_IN' })}
                </UiText>
            </div>
        </>
    );
};

export default EnterpriseSsoLoginComponent;
