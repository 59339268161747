import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { UiSocialButton } from '../../common/UiSocialButton';
import { logClick } from '../../telemetry';
import {
    setLastUsedLoginEmail,
    setLastUsedLoginMethod,
} from '../../util/CookieUtil';
import { useRegister } from '../../util/hooks/Register';

const useStyles = makeStyles((theme) =>
    createStyles({
        noHeightHidden: {
            height: '0px',
            overflow: 'hidden',
            opacity: 0,
            transition: 'height 300ms 250ms ease-in, opacity 300ms 000ms ease-in',
            visibility: 'hidden',
        },
        signup: {
            display: 'inline-flex',
            margin: '12px 0',
            visibility: 'visible',
        },
        link: {
            fontSize: '14px',
            fontWeight: 600,
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        defaultFontSize: { fontSize: '14px' },
        forgetAccount: {
            display: 'inline-flex',
            margin: '20px 0',
        },
        switchAndForgetAccount: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            width: '100%',
            opacity: 1,
            transition: 'height 500ms 000ms ease-out, opacity 300ms 000ms ease-out',
            visibility: 'visible',
        },
        socialButton: { borderColor: `${theme.palette.semantic.colorBorderDeEmp} !important` },
    }),
);

export const LoginFooterComponent: React.FC<{
    showOptionsFooter: boolean;
    setShowOptions: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    showOptionsFooter, setShowOptions,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const register = useRegister();
    const { reset } = useFormContext();

    const signInOtherAccount = useCallback(() => {
        reset();
        setShowOptions(true);
    }, [ reset, setShowOptions ]);

    return (
        <>
            <div
                className={clsx({
                    [classes.noHeightHidden]: showOptionsFooter,
                    [classes.switchAndForgetAccount]: !showOptionsFooter,
                })}
            >
                <UiSocialButton
                    className={classes.socialButton}
                    onClick={() => signInOtherAccount()}
                    textColor='#0067df'
                    data-cy="login-another-account"
                    variant='outlined'
                    fullWidth
                    centered
                >
                    {translate({ id: 'SIGN_IN_ANOTHER_ACCOUNT' })}
                </UiSocialButton>
                <div className={classes.forgetAccount}>
                    <UiText
                        className={classes.link}
                        onClick={() => {
                            setLastUsedLoginEmail('');
                            setLastUsedLoginMethod('');
                            setShowOptions(true);
                        }}
                        align="center"
                        data-cy="login-forget-account"
                        tabIndex={0}>
                        {translate({ id: 'FORGET_ACCOUNT' })}
                    </UiText>
                </div>
            </div>

            <div
                className={clsx({
                    [classes.signup]: showOptionsFooter,
                    [classes.noHeightHidden]: !showOptionsFooter,
                })}
            >
                <UiText className={classes.defaultFontSize}>
                    {translate({ id: 'DONT_HAVE_ACCOUNT' })}
                    &nbsp;
                </UiText>
                <UiText
                    className={classes.link}
                    onClick={() => {
                        logClick('LoginPageComponent', true, 'SIGN_UP');
                        register();
                    }}
                    data-cy="login-goto-signup"
                    tabIndex={0}>
                    {translate({ id: 'SIGN_UP' })}
                </UiText>
            </div>
        </>
    );
};
