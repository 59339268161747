import type { ButtonTypeMap } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { ExtendButtonBase } from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
    wrapper: { position: 'relative' },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const UiProgressButton: ExtendButtonBase<ButtonTypeMap<{ loading: boolean; outerClassName?: string }>> = (props: any) => {
    const classes = useStyles();
    const {
        loading, disabled, style, ...rest
    } = props;

    return (
        <div
            className={clsx(classes.wrapper, props.outerClassName)}
            style={style}>
            <Button
                {...rest}
                disabled={loading || disabled} />
            {props.loading && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress} />
            )}
        </div>
    );
};
