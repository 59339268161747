import { UiText } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import {
    getLastUsedLoginEmail,
    getLastUsedLoginMethod,
} from '../../util/CookieUtil';
import useAuthInstance from '../../util/hooks/AuthInstance';
import { useIsCustomerPortal } from '../../util/hooks/IsCustomerPortal';
import useLogin from '../../util/hooks/Login';
import Providers from '../../util/interfaces/provider';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: '20px',
            fontWeight: 600,
            margin: '16px 0',
        },
        success: {
            display: 'flex',
            alignSelf: 'flex-center',
        },
        infoText: {
            margin: '16px 0',
            fontSize: '14px',
        },
        defaultFontSize: { fontSize: '14px' },
        link: {
            fontSize: '14px',
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        backToLogin: { margin: '12px 0' },
    }),
);

const ResetPasswordComponent = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigateToLogin = useLogin();

    const getAuthInstance = useAuthInstance();

    const [ email, setEmail ] = useState('');
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ sent, setSent ] = useState<string | undefined>(undefined);

    const isCustomerPortal = useIsCustomerPortal();

    const defaultEmail = useMemo(() => {
        if (getLastUsedLoginMethod() === Providers.Email) {
            return getLastUsedLoginEmail() ?? '';
        }
        return '';
    }, []);

    const resetPassword = useCallback(
        (emailReset: string) => {
            if (!emailReset || !/^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,4})+$/.test(emailReset)) {
                setError(translate({ id: 'INVALID_EMAIL' }));
                return;
            }

            getAuthInstance().changePassword(emailReset, (err, resp) => {
                if (err) {
                    setError(translate({ id: 'INVALID_EMAIL' }));
                } else if (resp) {
                    setSent(translate({ id: 'PASSWORD_RESET_BASIC_AUTH' }));
                }
            });
        },
        [ getAuthInstance, translate ],
    );

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setError(undefined);
        setEmail(e.target.value);
    }, []);

    return (
        <>
            <UiText
                className={classes.title}
                role="heading"
                aria-level={1}>
                {translate({ id: 'RESET_YOUR_PASSWORD' })}
            </UiText>
            {sent && (
                <span
                    className={classes.success}
                    data-cy="reset-email-sent-message">
                    <PortalAlertBar
                        status='success'
                        cancelable={false}
                        theme="light">
                        {sent}
                    </PortalAlertBar>
                </span>
            )}
            <TextField
                variant="outlined"
                fullWidth
                label={!isCustomerPortal ? translate({ id: 'EMAIL' }) : translate({ id: 'EMAIL_BUSINESS_V2' })}
                InputLabelProps={{ id: 'emailLabel' }}
                onChange={onChange}
                error={!!error}
                helperText={error}
                defaultValue={defaultEmail}
                inputProps={{
                    'data-cy': 'reset-password-email-input',
                    'aria-labelledby': 'emailLabel',
                }}
            />
            <UiText className={classes.infoText}>
                {translate({ id: 'PASSWORD_RESET_TEXT' })}
            </UiText>
            <Button
                variant="contained"
                color="primary"
                onClick={() => resetPassword(email ? email : defaultEmail)}
                fullWidth
                data-cy="reset-password-button"
            >
                {translate({ id: 'RESET_PASSWORD' })}
            </Button>
            <div className={classes.backToLogin}>
                <UiText
                    display="inline"
                    className={classes.defaultFontSize}>
                    {translate({ id: 'BACK_TO' })}
                    &nbsp;
                </UiText>
                <UiText
                    display="inline"
                    className={classes.link}
                    onClick={() => navigateToLogin()}
                    data-cy="forgot-password-goto-login"
                    tabIndex={0}>
                    {translate({ id: 'SIGN_IN' })}
                </UiText>
            </div>
        </>
    );
};

export default ResetPasswordComponent ;
