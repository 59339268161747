import React from 'react';

import FreeSkuLeftContainer from './FreeSkuLeftContainer';
import FreeSkuRightContainer from './FreeSkuRightContainer';

const FreeSkuSignupPageComponent = () => (
    <>
        <FreeSkuLeftContainer />
        <FreeSkuRightContainer />
    </>
);

export default FreeSkuSignupPageComponent;
