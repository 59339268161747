import Cookies from 'js-cookie';

import getEnvironment from './EnvironmentUtil';
import Providers from './interfaces/provider';

const env = getEnvironment();

const CookieKeys = {
    lastUsedEnterpriseSsoOrg: `lastUsedEnterpriseSsoOrg-${env}`,
    lastUsedLoginEmail: `lastUsedLoginEmail-${env}`,
    lastUsedLoginMethod: `lastUsedLoginMethod-${env}`,
    currentLoginMethod: `currentLoginMethod-${env}`,
};

export const getLastUsedLoginEnterpriseSsoOrg = () => Cookies.get(CookieKeys.lastUsedEnterpriseSsoOrg);

export const setLastUsedLoginEnterpriseSsoOrg = (organization: string) => {
    Cookies.set(CookieKeys.lastUsedEnterpriseSsoOrg, organization, {
        domain: env === 'local' ? 'localhost' : '.uipath.com',
        expires: 60,
    });
};

export const getLastUsedLoginEmail = () => {
    const email = Cookies.get(CookieKeys.lastUsedLoginEmail)?.trim() ?? '';
    return /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,4})+$/.test(email) ? email : '';
};

export const setLastUsedLoginEmail = (email: string) => {
    Cookies.set(CookieKeys.lastUsedLoginEmail, email, {
        domain: env === 'local' ? 'localhost' : '.uipath.com',
        expires: 60,
    });
};

export const getLastUsedLoginMethod = (hideLinkedin = false, hideEnterpriseSSO = true) => {
    const method = Cookies.get(CookieKeys.lastUsedLoginMethod)?.trim();
    return method === Providers.Email ||
    method === Providers.Google ||
    method === Providers.Microsoft ||
    (method === Providers.LinkedIn && !hideLinkedin) ||
    (method === Providers.EnterpriseSSO && !hideEnterpriseSSO)
        ? method
        : '';
};

export const setLastUsedLoginMethod = (method: string) => {
    Cookies.set(CookieKeys.lastUsedLoginMethod, method, {
        domain: env === 'local' ? 'localhost' : '.uipath.com',
        expires: 60,
    });
};

export const setCurrentLoginMethod = (method: string) => {
    Cookies.set(CookieKeys.currentLoginMethod, method, {
        domain: env === 'local' ? 'localhost' : '.uipath.com',
        expires: 60,
    });
};
