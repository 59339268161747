import type { IParams } from './interfaces/params';

export const getParams = (search = window.location.search): IParams => {
    const urlParams = new URLSearchParams(search);
    return Object.fromEntries(urlParams.entries()) as any;
};

export default function isValidParamsForRegister(params: IParams) {
    return params.cloudrpa_signup_subdomain === '/portal_' && params.register_endpoint === '/register';
}
