import { UiSignupLayout } from '@experiences/ui-common';
import React from 'react';

import BottomPageOptions from '../../common/UiBackground/subcomponents/BottomPageOptions';
import InviteLeftContainer from './subcomponents/InviteLeftContainer';
import InviteRightContainer from './subcomponents/InviteRightContainer';

const InvitePageComponent = () => <UiSignupLayout
    left={<InviteLeftContainer />}
    right={<InviteRightContainer />}
    bottomOptions={<BottomPageOptions languageSelectorOnly />} />;

export default InvitePageComponent ;
