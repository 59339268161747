import {
    useEffect,
    useRef,
} from 'react';

// Taken from @experiences/util
export const useInterval = (callback: () => any, delay: number | null) => {
    const savedCallback = useRef<() => any>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [ callback ]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }

        if (delay != null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return () => void 0;
    }, [ delay ]);
};
