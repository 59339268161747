import { SubscriptionType } from '@experiences/constants';
import { UiText } from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, { useMemo } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { UiBackgroundSignup } from '../../../../common/UiBackground/UiBackgroundSignup';
import { LeftColor } from '../../../../common/UiBackground/UiBackgroundV3';
import UiList from '../../../../common/UiList/UiList';
import { useLocalization } from '../../../../container/Localization';
import { logClick } from '../../../../telemetry';
import palette from '../../../../theme/palette';
import { useSignupScreenExperiment } from '../../../../util/ExperimentUtil';
import { useRegister } from '../../../../util/hooks/Register';
import getLinks from '../util/Links';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            color: palette.light.title.primary,
            width: '100%',
            flexShrink: 0,
            fontSize: '32px',
            lineHeight: '32px',
            fontWeight: 500,
            fontFamily: 'Poppins',
            marginBottom: '38px',
        },
        titleName: { color: palette.light.title.tertiary },
        section: {
            marginTop: '40px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flexShrink: 0,
        },
        listHeader: {
            color: palette.light.list.header,
            marginBottom: '16px',
            fontWeight: 700,
        },
        header: {
            color: palette.light.subtitle,
            fontSize: '14px',
            fontWeight: 600,
        },
        link: {
            zIndex: 1,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            marginTop: '8px',
            display: 'table',
            cursor: 'pointer',
            color: palette.light.link,
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
        },
        list: {
            marginTop: '24px',
            width: '100%',
            flexShrink: 0,
        },
        trademark: {
            fontWeight: 400,
            fontSize: '8px',
            position: 'relative',
            top: '-2.2em',
        },
        titleExp: {
            fontFamily: Tokens.FontFamily.FontHeader2BoldFamily,
            fontWeight: Tokens.FontFamily.FontWeightBold,
            lineHeight: '48px',
            fontSize: Tokens.FontFamily.FontHeader2BoldSize,
        },
        titleNameExp: { color: palette.backgroundOrange.secondary },
        headerExp: {
            fontFamily: Tokens.FontFamily.FontHeader2BoldFamily,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            fontSize: Tokens.FontFamily.FontBrandLSize,
            lineHeight: '28px',
        },
    }),
);

const AutomationCloudForCommunity = () => {
    const classes = useStyles();
    const register = useRegister();

    const language = useLocalization();
    const links = useMemo(() => getLinks(language), [ language ]);
    const { formatMessage: translate } = useIntl();

    const isExperimentActive = useSignupScreenExperiment();

    const items = useMemo(() =>
        [
            'COMMUNITY_SIGNUP_1',
            'COMMUNITY_SIGNUP_2',
            'COMMUNITY_SIGNUP_3',
            'COMMUNITY_SIGNUP_4',
        ], []) ;

    return (
        <UiBackgroundSignup
            isExperiment={isExperimentActive}
            color={LeftColor.Orange}>
            <UiText
                className={isExperimentActive ? classes.titleExp : classes.title}
                data-cy="automation-cloud-community-title">
                <FormattedMessage
                    id="AC_COMMUNITY_HEADER"
                    description="Welcome Message"
                    values={{
                        // eslint-disable-next-line react/no-unstable-nested-components
                        title: (chunks: React.ReactNode[]) => (
                            <span className={isExperimentActive ? classes.titleNameExp : classes.titleName}>
                                {chunks}
                            </span>
                        ),
                        // eslint-disable-next-line react/no-unstable-nested-components
                        tm: (chunks: React.ReactNode[]) => <span className={classes.trademark}>
                            {chunks}
                        </span>,
                    }}
                />
            </UiText>
            {!isExperimentActive && <div
                className={classes.list}
                data-cy="automation-cloud-list">
                <UiText
                    role='heading'
                    aria-level={6}
                    className={classes.listHeader}>
                    {translate({ id: 'COMMUNITY_SMALL_TEAMS' })}
                </UiText>
                <UiList
                    items={items}
                    colorListItemCheckmark="green"
                />
            </div>}
            <div className={classes.section}>
                <UiText className={isExperimentActive ? classes.headerExp : classes.header}>
                    {translate({ id: 'NEED_ROBOTS_COMMUNITY' })}
                </UiText>
                <UiText
                    className={classes.link}
                    onClick={() => {
                        logClick(AutomationCloudForCommunity.name, true, 'TRY_AUTOMATION_CLOUD');
                        register({ subscriptionPlan: SubscriptionType.TRIAL });
                    }}
                    data-cy="try-automation-cloud-link">
                    {translate({ id: 'TRY_AUTOMATION_CLOUD' })}
                </UiText>
            </div>
            <div className={classes.section}>
                <UiText className={isExperimentActive ? classes.headerExp : classes.header}>
                    {translate({ id: 'PREFER_ONPREM' })}
                </UiText>
                <Link
                    href={links.enterpriseServer}
                    onClick={() => {
                        logClick(AutomationCloudForCommunity.name, true, 'TRY_ENTERPRISE_ON_PREM');
                    }}
                    data-cy="try-enterprise-server-link"
                >
                    <UiText className={classes.link}>
                        {translate({ id: 'TRY_ENTERPRISE_ON_PREM' })}
                    </UiText>
                </Link>
                <Link
                    href={links.enterpriseStudio}
                    onClick={() => {
                        logClick(AutomationCloudForCommunity.name, true, 'TRY_ENTERPRISE_STUDIO');
                    }}
                    data-cy="try-enterprise-studio-link"
                >
                    <UiText className={classes.link}>
                        {translate({ id: 'TRY_ENTERPRISE_STUDIO' })}
                    </UiText>
                </Link>
            </div>
        </UiBackgroundSignup>
    );
};

export default AutomationCloudForCommunity;
