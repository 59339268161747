import { UiText } from '@experiences/ui-common';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { UiProgressButton } from '../../../common/UiProgressButton/UiProgressButton';
import UiVerificationCodeInput from '../../../common/UiVerification/UiVerificationCodeInput';
import { useParams } from '../../../container/Router';
import { logClick } from '../../../telemetry';
import { getOrganizationAppOriginFromEnv } from '../../../util/ConfigUtil';
import getEnvironment from '../../../util/EnvironmentUtil';
import { useInterval } from '../../../util/hooks/Interval';

const AUTH_ORIGIN = getEnvironment() === 'local' ? 'https://id-dev.uipath.com' : window.location.origin;
const PORTAL_ORIGIN = getEnvironment() === 'local' ? require('../../../envConfigs/nst.json').PORTAL_ORIGIN
    : getOrganizationAppOriginFromEnv();

// Ensure this matches the rate limit on the Auth0 backend
const RESEND_EMAIL_TIMEOUT_INTERVAL_SECONDS = 60;

/**
 * An enum object that maps to strings for the query parameter "response_message"
 * Duplicated on Auth0 backend
 */
enum ResponseMessage {
    EMAIL_SENT = 'email_sent',
    INVALID_CODE = 'invalid_code',
    EMAIL_RATE_LIMIT = 'email_rate_limit',
    ATTEMPT_LIMIT = 'attempt_limit',
}

const useStyles = makeStyles(() =>
    createStyles({
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '8px',
        },
        submitButton: {
            width: '360px',
            marginTop: '52px',
        },

        resendButton: { textDecoration: 'none' },
        error: { marginBottom: '6px' },
        emailChangeMessage: {
            position: 'relative',
            marginTop: '64px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
        emailChangeLink: { cursor: 'pointer' },
    }),
);

interface ICode {
    code: string;
}

export const VerificationFormComponent: React.FC = () => {
    const {
        state,
        response_message,
        client, // Application client ID passed from Auth0, used for /logout endpoint if user clicks the change email button
    } = useParams();
    const classes = useStyles();

    const [ resendEmailTimeRemaining, setResendEmailTimeRemaining ] = useState<number | null>(null);

    // Used to prevent user from clicking buttons consecutively before redirect finishes
    const [ submitLoading, setSubmitLoading ] = useState<boolean>(false);
    const [ resendLoading, setResendLoading ] = useState<boolean>(false);
    const [ exitLoading, setExitLoading ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(false);

    useEffect(() => {
        setLoading(submitLoading || resendLoading || exitLoading);
    }, [ submitLoading, resendLoading, exitLoading ]);

    const { formatMessage: translate } = useIntl();

    const {
        handleSubmit,
        setError,
        control,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: { code: '' },
    });

    const setFormError = useCallback(
        () => {
            switch (response_message) {
                case ResponseMessage.INVALID_CODE:
                    setError('code', {
                        type: 'invalid',
                        message: 'VERIFICATION_INVALID_CODE',
                    });
                    break;
                case ResponseMessage.EMAIL_RATE_LIMIT:
                    setError('code', {
                        type: 'invalid',
                        message: 'VERIFICATION_EMAIL_RATE_LIMIT',
                    });
                    break;
                case ResponseMessage.ATTEMPT_LIMIT:
                    setError('code', {
                        type: 'invalid',
                        message: 'VERIFICATION_CODE_ATTEMPT_LIMIT',
                    });
                    break;
                default:
            }
        },
        [ response_message, setError ],
    );

    useEffect(() => {
        setFormError();
        if (response_message === ResponseMessage.EMAIL_SENT) {
            setResendEmailTimeRemaining(RESEND_EMAIL_TIMEOUT_INTERVAL_SECONDS);
        }
    }, [ response_message, setFormError ]);

    useInterval(
        () => {
            if (resendEmailTimeRemaining != null) {
                setResendEmailTimeRemaining(resendEmailTimeRemaining - 1);
                if (resendEmailTimeRemaining === 1) {
                    setResendEmailTimeRemaining(null);
                }
            }
        },
        resendEmailTimeRemaining ? 1000 : null,
    );

    const submitCode = useCallback(
        (data: ICode) => {
            // Ensure this callback only occurs if no redirect is already loading
            if (!loading) {
                setSubmitLoading(true);
                logClick('email_verification', true, 'SUBMIT_CODE');
                window.location.replace(`${ AUTH_ORIGIN }/continue?state=${ state }&code=${ data.code }`);
            }
        },
        [ loading, state ],
    );

    const resendVerificationEmail = useCallback(
        () => {
            if (!loading) {
                setResendLoading(true);
                logClick('email_verification', true, 'RESEND_VERIFICATION_EMAIL');
                window.location.replace(`${ AUTH_ORIGIN }/continue?state=${ state }&resend=true`);
            }
        },
        [ loading, state ],
    );

    const exitLogin = useCallback(
        () => {
            if (!loading) {
                setExitLoading(true);
                logClick('email_verification', true, 'EXIT_LOGIN');
                window.location.replace(`${ AUTH_ORIGIN }/logout?client_id=${ client }&returnTo=${ PORTAL_ORIGIN }/portal_/cloudrpa`);
            }
        },
        [ loading, client ],
    );

    return (
        <div className={classes.formContainer}>
            <form
                onSubmit={handleSubmit(submitCode)}
                className={classes.form}>
                <Controller
                    render={({
                        field, fieldState,
                    }) => (
                        <UiVerificationCodeInput
                            value={field.value}
                            onChange={field.onChange}
                            error={!!fieldState.error?.message}
                            helperText={fieldState.error?.message ? translate({ id: fieldState.error.message.toString() }) : ''}
                            loading={submitLoading}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            validChars="0-9"
                            label={translate({ id: 'VERIFICATION_INPUT_LABEL' })}
                            placeholder=''
                            inputProps={{
                                'data-cy': 'code-input',
                                'aria-labelledby': 'codeLabel',
                            }}
                        />
                    )}
                    name="code"
                    control={control}
                    rules={{
                        required: true,
                        minLength: 6,
                        maxLength: 6,
                    }}
                />
                <UiProgressButton
                    loading={submitLoading}
                    disabled={!isValid}
                    variant="contained"
                    fullWidth
                    type='submit'
                    outerClassName={classes.submitButton}
                    data-cy="email-verification-submit-button">
                    {translate({ id: 'CONTINUE' })}
                </UiProgressButton>
            </form>
            <Collapse in={!submitLoading}>
                <UiProgressButton
                    loading={resendLoading}
                    disabled={resendEmailTimeRemaining != null}
                    onClick={resendVerificationEmail}
                    className={classes.resendButton}
                    data-cy="email-verification-resend-email-button"
                >
                    {`${translate({ id: 'RESEND_EMAIL' })} ${
                        resendEmailTimeRemaining != null ? '(' + resendEmailTimeRemaining + ')' : ''
                    }`}
                </UiProgressButton>
            </Collapse>
            <UiText className={classes.emailChangeMessage}>
                {translate(
                    { id: 'VERIFICATION_CHANGE_EMAIL' },
                    {
                        createAccount: (
                            <Link
                                className={classes.emailChangeLink}
                                underline='none'
                                onClick={exitLogin}>
                                {translate({ id: 'VERIFICATION_CREATE_NEW_ACCOUNT' })}
                            </Link>
                        ),
                    },
                )}
            </UiText>
        </div>
    );
};
