export default function getEnvironment() {
    const origin = window.location.origin.toLowerCase();
    if (origin.indexOf('localhost') >= 0) {
        return 'local';
    } else if (origin.indexOf('alpha') >= 0) {
        return 'alp';
    } else if (origin.indexOf('preprod') >= 0) {
        return 'stg';
    } else if (origin.indexOf('account') >= 0) {
        return 'prd';
    }
    return 'nst';
}

const environment = getEnvironment();

const _config = require(`../envConfigs/${environment}.json`);

export function getEnvironmentConfig() {
    return _config;
}

export function isLocal() {
    return environment === 'local';
}

export function getStatsigEnvironment() {
    switch (environment) {
        case 'local':
        case 'nst':
            return 'development';
        case 'alp':
            return 'alpha';
        case 'stg':
            return 'staging';
        case 'prd':
            return 'production';
    }
}
