import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiBackgroundV2 } from '../../../common/UiBackground/UiBackgroundV2';
import { useParams } from '../../../container/Router';
import Robot from '../../../images/Robot.svg';
import palette from '../../../theme/palette';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            color: palette.light.title.primary,
            width: '100%',
            flexShrink: 0,
            fontSize: '32px',
            lineHeight: '32px',
            fontWeight: 500,
            fontFamily: 'Poppins',
            marginBottom: '38px',
        },
        titleName: { color: palette.light.title.tertiary },
        descriptionText: {
            marginTop: '40px',
            display: 'flex',
            flexDirection: 'column',
            width: '70%',
            flexShrink: 0,
        },
        header: { color: palette.light.subtitle },
        trademark: {
            fontWeight: 400,
            fontSize: '8px',
            position: 'relative',
            top: '-0.4em',
        },
    }),
);

const InviteLeftContainer = () => {
    const { organizationName } = useParams();
    const classes = useStyles();

    const { formatMessage: translate } = useIntl();

    return (
        <UiBackgroundV2
            shapeColor={palette.light.background.primary}
            WelcomeImage={Robot}>
            {organizationName ?
                <>
                    <UiText className={classes.title}>
                        {translate({ id: 'JOIN_AC_ORG' }, {
                            ORG: <UiText
                                display="inline"
                                className={clsx(classes.titleName, classes.title)}>
                                {organizationName}
                                <br />
                            </UiText>,
                            TM: (
                                <sup className={classes.trademark}>
                                    TM
                                </sup>
                            ),
                        })}
                    </UiText>

                </> : <UiText className={classes.title}>
                    {translate({ id: 'JOIN_AC_NO_ORG_NAME' }, {
                        TM: (
                            <sup className={classes.trademark}>
                                TM
                            </sup>
                        ),
                    })}
                </UiText>}
            <UiText className={classes.descriptionText}>
                {translate({ id: 'INVITATION_SIGN_IN_MESSAGE' })}
            </UiText>
        </UiBackgroundV2>
    );
};

export default InviteLeftContainer ;
