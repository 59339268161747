import { SubscriptionType } from '@experiences/constants';
import { isValidServiceRedirect } from '@experiences/util';
import { useCallback } from 'react';

import {
    useParams,
    useRouter,
} from '../../container/Router';
import { getOrganizationAppOriginFromEnv } from '../ConfigUtil';
import getEnvironment from '../EnvironmentUtil';
import type { IExtraParams } from '../interfaces/extraparams';
import isValidParamsForRegister from '../RouteUtil';
import { useFreeSku } from './FreeSku';

function navigateToGlobalAppRegister(
    useLocalRegistration: boolean,
    ecommerceRedirect: string,
    subscriptionPlan?: string,
    extraParams?: IExtraParams,
) {
    const plan = isValidServiceRedirect(subscriptionPlan) ? subscriptionPlan : SubscriptionType.TRIAL;
    const url = new URL(`${getOrganizationAppOriginFromEnv({
        useLocal: useLocalRegistration,
        useGlobal: true,
    })}/portal_/register?subscriptionPlan=${plan}`);
    extraParams &&
    Object.entries({
        ...extraParams,
        ecommerceRedirect,
    })
        .filter(([ , v ]) => !!v)
        .forEach(([ key, value ]) => url.searchParams.set(key, value));

    window.location.assign(url.toString());
}

export const useRegister = () => {
    const params = useParams();
    const nextRoute = useRouter();
    const {
        isFreeSkuRegister, isSecondaryFreeSkuRegister, retryUrl,
    } = useFreeSku();

    return useCallback(
        (extraParams?: IExtraParams) => {
            const useLocalRegistration = params.use_local_registration === 'true';
            const ecommerceRedirect = params.ecommerceRedirect || 'false';
            const subscriptionPlan = params.subscription_plan;
            const serviceRedirectUri = params.service_redirect_uri;

            const isLocalRegister = !useLocalRegistration && getEnvironment() === 'local';
            const isInvalidParams = !isValidParamsForRegister(params);

            if (serviceRedirectUri) {
                extraParams = {
                    ...extraParams,
                    service_redirect_uri: serviceRedirectUri,
                };
            }

            if (isSecondaryFreeSkuRegister || isFreeSkuRegister) {
                extraParams = {
                    ...extraParams,
                    subscriptionPlan: 'automationkit',
                    retryUrl,
                };
                navigateToGlobalAppRegister(useLocalRegistration, ecommerceRedirect, subscriptionPlan, extraParams);
                return;
            }

            if (isLocalRegister || isInvalidParams) {
                nextRoute({
                    ...extraParams,
                    ecommerceRedirect,
                    type: 'signup',
                });
                return;
            }

            navigateToGlobalAppRegister(useLocalRegistration, ecommerceRedirect, subscriptionPlan, extraParams);
        },
        [ nextRoute, params, isFreeSkuRegister, isSecondaryFreeSkuRegister, retryUrl ],
    );
};
