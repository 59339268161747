import { UiLogo } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import type { ApolloThemeType } from '@uipath/portal-shell-util';
import type { PropsWithChildren } from 'react';
import React from 'react';

import palette from '../../theme/palette';

export enum LeftColor {
    Orange = 'orange',
    Blue = 'blue',
    Green = 'green',
}

interface LeftColorProperties {
    background: string;
    card: string;
}

const colorMap: Record<LeftColor, LeftColorProperties> = {
    [LeftColor.Green]: {
        background: palette.backgroundGreen.primary,
        card: palette.backgroundGreen.secondary,
    },
    [LeftColor.Orange]: {
        background: palette.backgroundOrange.primary,
        card: palette.backgroundOrange.secondary,
    },
    [LeftColor.Blue]: {
        background: palette.backgroundBlue.primary,
        card: palette.backgroundBlue.secondary,
    },
};

const useStyles = makeStyles<ApolloThemeType, { color: LeftColor }>({
    container: {
        width: '100%',
        display: 'flex',
        paddingLeft: '48px',
        flexDirection: 'column',
    },
    logo: {
        zIndex: 3,
        margin: '48px 0px',
    },
    cardForeground: {
        width: '90%',
        zIndex: 2,
        borderRadius: '24px 24px 0px 0px',
        backgroundColor: 'white',
        flex: 1,
        boxShadow: ({ color }) => `
            20px -20px ${colorMap[color].card}, 
            20px 0 ${colorMap[color].card},
            -128px -256px 0 0em ${colorMap[color].background},
            -128px 0px 0 0em ${colorMap[color].background}
        `,

    },
});

export const UiBackgroundV3 = ({
    color, children,
}: PropsWithChildren<{ color: LeftColor }>) => {
    const classes = useStyles({ color });

    return (
        <div className={classes.container}>
            <UiLogo className={classes.logo} />
            <div className={classes.cardForeground} >
                <div style={{ padding: '48px 120px 0px 48px' }}>
                    {children}
                </div>
            </div>
        </div>
    );
};
