export default function getLinks(language: string): { enterpriseServer: string; enterpriseStudio: string } {
    switch (language) {
        case 'fr':
            return {
                enterpriseServer: 'https://www.uipath.com/fr/developers/enterprise-edition-download',
                enterpriseStudio: 'https://www.uipath.com/developers/studio-download',
            };
        case 'ja':
        case 'ko':
            return {
                enterpriseServer: `https://www.uipath.com/${language}/developers/enterprise-edition-download`,
                enterpriseStudio: `https://www.uipath.com/${language}/developers/studio-download`,
            };
        case 'cn':
            return {
                enterpriseServer: 'https://www.uipath.com.cn/developers/enterprise-edition-download',
                enterpriseStudio: 'https://www.uipath.com.cn/developers/studio-download',
            };
        case 'de':
        case 'es':
        default:
            return {
                enterpriseServer: 'https://www.uipath.com/developers/enterprise-edition-download',
                enterpriseStudio: 'https://www.uipath.com/developers/studio-download',
            };
    }
}
