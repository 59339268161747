import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import palette from '../../../../theme/palette';

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            position: 'relative',
            width: '100%',
            minWidth: '700px',
            height: 0,
        },
        viewBox: {
            left: '-307px',
            bottom: '0px',
            position: 'absolute',
            width: '540px',
            minHeight: '177px',
            overflow: 'hidden',
        },
        root: {
            bottom: '-78px',
            right: '0px',
            position: 'absolute',
            width: '228px',
            height: '228px',
            opacity: '0.1',
            borderWidth: '156px',
            borderStyle: 'solid',
            borderRadius: '50%',
        },
    }),
);

const Arc: React.FC<{
    color?: string;
}> = ({ color }) => {
    const classes = useStyles();

    return (
        <div className={classes.box}>
            <div className={classes.viewBox}>
                <div
                    className={classes.root}
                    style={{ borderColor: color ?? palette.light.shape }} />
            </div>
        </div>
    );
};

export default Arc;
