import { useMemo } from 'react';

import { useParams } from '../../container/Router';
import {
    Features,
    useFeatureFlagValue,
} from '../FeatureFlagUtil';
import { PlatformName } from './SignupType';

export default function useIsSignupWithText() {
    const { platform_name } = useParams();

    const EnableCommunitySignUpText = useFeatureFlagValue(Features.EnableCommunitySignUpText.name);
    const EnableFreeSignUpText = useFeatureFlagValue(Features.EnableFreeSignUpText.name);
    const EnableAHSignUpText = useFeatureFlagValue(Features.EnableAHSignUpText.name);

    return useMemo(() => (PlatformName.UiEnterprise === platform_name)
    || (EnableCommunitySignUpText
        && [
            PlatformName.UiCommunity,
            PlatformName.UiPlatform,
            PlatformName.UiAutomationCloud,
        ].includes(platform_name))
    || (EnableFreeSignUpText && platform_name === PlatformName.UiFree)
    || (EnableAHSignUpText && platform_name === PlatformName.UiAutomationHub),
    [ platform_name, EnableCommunitySignUpText, EnableFreeSignUpText, EnableAHSignUpText ]);
}
