import { UiLogo } from '@experiences/ui-common';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import Arc from './subcomponents/shapes/Arc_Redesign';
import Circle from './subcomponents/shapes/Circle_Redesign';
import ImageContainer from './subcomponents/shapes/ImageContainer';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundPosition: '0 0',
            backgroundSize: '24px 24px',
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
        },
        content: {
            width: '525px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '48px 50px 24px 50px',
            minHeight: '400px',
            zIndex: 1,
        },
        textArea: {
            paddingLeft: '24px',
            marginTop: '48px',
        },
    }),
);

export const UiBackgroundV2: React.FC<{
    children?: React.ReactNode;
    shapeColor?: string;
    showShapes?: boolean;
    WelcomeImage?: string; }> = ({
    children, shapeColor, WelcomeImage, showShapes = true,
}) => {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <UiLogo
                    color={theme.palette.semantic.colorLogo}
                    data-cy="uipath-logo" />
                <div className={classes.textArea}>
                    {children}
                </div>
            </div>
            { showShapes && <>
                <Arc color={shapeColor} />
                <Circle color={shapeColor} />
                {WelcomeImage && <ImageContainer image={WelcomeImage} />}
            </>}
        </div>
    );
};
