const maskStr = '****';
const tokenRegex = /Bearer ey(.*)/g;
const emailRegex = /(.*)@(.*).(.*)/g;

const fieldsToMask = [ 'username', 'surname', 'email', 'password', 'x-uipath-s2s-iprange' ];

/**
 *
 * @param search Regular expression used in the replacer function to mask an object with matching values.
 * If search is undefined, then only keys matching fieldsToMask will be masked.
 * @returns Replacer function that masks values that match the given regular expression and keys in fieldsToMask
 */
const replacer = (search?: RegExp | RegExp[]) => (key: string, value: any) => {
    // filter out fields
    if (fieldsToMask.includes(key.toLowerCase())) {
        return maskStr;
    }

    // test fields for regex
    if (typeof value === 'string' && search) {
        return mask(value, search);
    }
    return value;
};

export function mask(str: string, search: RegExp | RegExp[]) {
    return Array.isArray(search) ?
        search.reduce((acc, curr) => acc = acc.replace(curr, maskStr), str) :
        str.replace(search, maskStr);
}

export function maskObject(obj: object, search: RegExp | RegExp[]) {
    if (!obj) {
        return obj;
    }

    try {
        const maskedJson = JSON.stringify(obj, replacer(search));
        return JSON.parse(maskedJson);
    } catch (error) {
        return obj;
    }
}

/**
 *
 * @param obj Error object whose data property will be sanitized. Header sanitization should be handled by axiosResponseCleaner.
 * @returns Object with data stripped of sensitive info, while leaving other properties
 */
export function maskError(obj: any) {
    if (!obj) {
        return obj;
    }

    try {
        if (obj.data !== undefined) {
            const data = JSON.stringify(obj.data, replacer());
            obj.data = JSON.parse(data);
        }
        return obj;
    } catch (err) {
        return err;
    }
}

export function maskPii(obj: any) {
    return maskObject(obj, [ tokenRegex, emailRegex ]);
}
