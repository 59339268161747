import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import palette from '../../theme/palette';
import BottomPageOptions from './subcomponents/BottomPageOptions';
import Arc from './subcomponents/shapes/Arc';
import Circle from './subcomponents/shapes/Circle';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundPosition: '0 0',
            backgroundSize: '24px 24px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            overflowY: 'auto',
            overflowX: 'hidden',
            flexDirection: 'column',
            position: 'relative',
        },
        textArea: {
            width: '350px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '100px 50px 24px 50px',
            minHeight: '400px',
        },
    }),
);

export const UiBackground: React.FC<{
    children?: React.ReactNode;
    className?: string;
    bottomOptions?: boolean;
    backgroundColor?: { primary: string; secondary: string };
    shapeColor?: string;
}> = ({
    children, className, bottomOptions, backgroundColor, shapeColor,
}) => {
    const classes = useStyles();

    return (
        <div
            className={`${classes.root} ${className ?? ''}`}
            style={{
                backgroundImage: `radial-gradient(circle, ${
                    backgroundColor?.secondary ?? palette.light.background.secondary
                } 7%, ${backgroundColor?.primary ?? palette.light.background.primary} 7%)`,
            }}
        >
            {bottomOptions ? (
                <>
                    <>
                        {children}
                    </>
                    <BottomPageOptions />
                </>
            ) : (
                <>
                    <div
                        className={classes.textArea}
                        style={{ background: backgroundColor?.primary ?? palette.light.background.primary }}
                    >
                        {children}
                    </div>
                    <Circle color={shapeColor} />
                    <Arc color={shapeColor} />
                </>
            )}
        </div>
    );
};
