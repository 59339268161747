import { Statsig } from 'statsig-react';

import { getFeatureFlagOverrides } from './ConfigUtil';
import type { IFeatures } from './interfaces/feature';

export const Features: IFeatures = {
    /** For feature flags to work in the Auth0 SPA, you must check the Client-Side SDK setting in LD */
    EnableEcommerceIntegration: {
        name: 'enableEcommerceIntegration',
        defaultValue: true,
    },
    EnableEcommercePromotionalDiscounts: {
        name: 'enableEcommercePromotionalDiscounts',
        defaultValue: true,
    },
    EnableLocKeysLanguage: {
        name: 'enable-loc-keys-language',
        defaultValue: false,
    },
    EnableCommunitySignUpText: {
        name: 'EnableCommunitySignUpText',
        defaultValue: false,
    },
    EnableFreeSignUpText: {
        name: 'EnableFreeSignUpText',
        defaultValue: false,
    },
    EnableAHSignUpText: {
        name: 'EnableAHSignUpText',
        defaultValue: false,
    },
    EnableLocalStorageFeatureFlags: {
        name: 'EnableLocalStorageFeatureFlags',
        defaultValue: false,
    },
};

const DefaultValuesMap = Object.keys(Features).reduce((accumulator, current) => {
    const value = Features[current];
    accumulator[value.name] = value.defaultValue;
    return accumulator;
}, {} as { [key: string]: boolean });

const getFlagDefinition = (key: string) => Object.entries(Features).find(([ _, value ]) => value.name === key)?.[1];

const featureFlagOverrides = getFeatureFlagOverrides();

/**
 * Order:
 * 1. localstorage feature flag overrides
 * 2. Statsig flag definition
 * 3. JSON file overrides
 * 4. DefaultValuesMap
 *
 * Note: when a flag is false in Statsig, it will fall through
 */
export const useFeatureFlagValue = (flag: string): boolean => {
    const featureKey = getFlagDefinition(flag)?.name;

    // feature isn't defined in the Features object
    if (!featureKey) {
        return false;
    }

    // check for localstorage overrides
    if (isLocalFeatureFlagsEnabled && localStorage.getItem(featureKey) != null) {
        return localStorage.getItem(featureKey)?.toLowerCase() === 'true';
    }

    // get flag from statsig, without exposure logging since the feature flags are static
    const statsigFlag = Statsig.checkGateWithExposureLoggingDisabled(featureKey.toLowerCase());

    // return Statsig flag value
    if (statsigFlag) {
        return statsigFlag;
    }

    // fallback to feature flag overrides or default values if no override
    return featureFlagOverrides?.[featureKey] ?? DefaultValuesMap[featureKey];
};

export const isLocalFeatureFlagsEnabled =
    typeof window !== 'undefined' &&
        (window as any).Cypress || window.localStorage.getItem(Features.EnableLocalStorageFeatureFlags.name) === 'true';
