import { SubscriptionType } from '@experiences/constants';
import { useMemo } from 'react';
import { Statsig } from 'statsig-react';

import { useParams } from '../container/Router';

const SignupScreenExperimentFreeName = 'simplifiedsignuppageexperiment-free';
const SignupScreenExperimentCommunityName = 'simplifiedsignuppageexperiment-community';
const SignupScreenExperimentTrialName = 'simplifiedsignuppageexperiment-trial';

export const useSignupScreenExperiment = () => {
    const params = useParams();

    const subscriptionPlan = params.subscription_plan;

    const isExperimentActive = useMemo(() => {
        const isFree = subscriptionPlan === SubscriptionType.FREE_SKU;
        const isCommunity = !subscriptionPlan || subscriptionPlan === SubscriptionType.COMMUNITY;
        const isTrial = subscriptionPlan === SubscriptionType.TRIAL;

        if (isFree) {
            return Statsig.getExperiment(SignupScreenExperimentFreeName).get('isExperimentActive', false);
        }

        if (isCommunity) {
            return Statsig.getExperiment(SignupScreenExperimentCommunityName).get('isExperimentActive', false);
        }

        if (isTrial) {
            return Statsig.getExperiment(SignupScreenExperimentTrialName).get('isExperimentActive', false);
        }

        return false;
    }, [ subscriptionPlan ]);

    return isExperimentActive;
};
