import { UiLogo } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import BottomPageOptions from '../UiBackground/subcomponents/BottomPageOptions';

const useStyles = makeStyles(() =>
    createStyles({
        pageContainer: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        },
        logo: {
            position: 'absolute',
            top: '5%',
        },
    }),
);

interface ISignupChildrenProps {
    center: JSX.Element;
    withLogo: boolean;
}

export const UiVerificationLayout = ({
    center, withLogo,
}: ISignupChildrenProps) => {
    const classes = useStyles();

    return (
        <div className={classes.pageContainer}>
            {withLogo && <UiLogo className={classes.logo} />}
            {center}
            <BottomPageOptions languageSelectorOnly={!withLogo} />
        </div>
    );
};
