import { UiText } from '@experiences/ui-common';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        userInfo: {
            display: 'flex',
            marginBottom: '16px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        avatar: {
            marginRight: '12px',
            background: '#F1F6F8',
            color: '#526069',
        },
        userText: {
            color: '#526069',
            lineHeight: '20px',
            maxWidth: '228px',
            overflowWrap: 'break-word',
        },
    }),
);

export const LoginProfileComponent: React.FC<{
    email: string;
    provider?: JSX.Element;
    isSignup?: boolean;
}> = ({
    email, provider, isSignup = false,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <>
            <div className={classes.userInfo}>
                <Avatar className={classes.avatar}>
                    <PersonOutlineOutlinedIcon />
                </Avatar>
                <div>
                    {isSignup &&
                    <UiText className={classes.userText}>
                        {translate({ id: 'SIGN_UP_WITH' }, { 0: '' })}
                    </UiText>}
                    <UiText
                        className={classes.userText}
                        data-cy="current-profile-email">
                        <strong>
                            {email}
                        </strong>
                    </UiText>
                </div>
            </div>
            {provider}
        </>
    );
};
