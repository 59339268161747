import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 4,
            position: 'relative',
            overflow: 'visible',
            width: '100%',
            minWidth: '700px',
        },
        root: {
            bottom: '0px',
            right: '0px',
            position: 'absolute',
            width: '100%',
            height: '180%',
        },
        '@media all and (max-height: 1000px)': { root: { display: 'none' } },
    }),
);

const ImageContainer: React.FC<{
    image: string ;
}> = ({ image }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={classes.box}>
            <img
                className={classes.root}
                src={image}
                alt={translate({ id: 'BANNER_IMAGE_ALT' })} />
        </div>
    );
};

export default ImageContainer ;
