import { UiLogo } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

import freeSkuImage from '../../images/freeskuimage.png';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            paddingTop: '40px',
        },
        leftContainer: {
            flexGrow: 3,
            flexDirection: 'column',
            marginLeft: '10vw',
        },
        logo: { marginBottom: '12px' },
        image: {
            backgroundImage: `url(${freeSkuImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            flexGrow: 15,
            flexShrink: 0,
        },
        '@media all and (max-width: 768px)': { leftContainer: { display: 'none' } },
    }),
);

const FreeSkuLeftContainer = () => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.container, classes.leftContainer)}>
            <UiLogo className={classes.logo} />
            <div className={classes.image} />
            <div style={{ flexGrow: 1 }} />
        </div>
    );
};

export default FreeSkuLeftContainer;
