import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import Mail from '../../images/Mail.svg';

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '15vh',
        },
        mailImage: { width: '109px' },
        title: {
            margin: '58px 0px 14px',
            fontSize: '24px',
            fontWeight: 700,
            color: theme.palette.semantic.colorForeground,
        },
        info: {
            fontSize: '14px',
            marginBottom: '4px',
            width: '552px',
            textAlign: 'center',
            fontWeight: 400,
            color: theme.palette.semantic.colorForeground,
        },
        form: { marginTop: '20px' },
    }),
);

interface IVerificationContainerProps {
    form: JSX.Element;
}
export const UiVerificationContainer = ({ form }: IVerificationContainerProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={classes.container}>
            <img
                className={classes.mailImage}
                src={Mail}
                alt={translate({ id: 'EMAIL_ICON_ALT' })} />

            <UiText
                align="center"
                data-cy="ui-verification-title"
                className={classes.title}>
                {translate({ id: 'VERIFICATION_TITLE' })}
            </UiText>
            <UiText className={classes.info}>
                {translate({ id: 'VERIFICATION_INFO' })}
            </UiText>

            <div
                className={classes.form}>
                {form}
            </div>

        </div>
    );
};
