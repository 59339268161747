export interface IAuthErrorDetail {
    message: string;
    actionMessage?: string;
    dataCy: string;
}

type IAuthError = Record<string, IAuthErrorDetail>;

export const AuthErrorMap: IAuthError = {
    userExists: {
        dataCy: 'signup-account-exists-error',
        message: 'ACCOUNT_ALREADY_EXISTS_ERROR',
        actionMessage: 'SIGN_IN',
    },
    useSso: {
        dataCy: 'signup-use-sso-error',
        message: 'BASIC_AUTH_DISABLED',
        actionMessage: 'SIGN_IN_SSO',
    },
    invalidDomain: {
        dataCy: 'signup-use-sso-error',
        message: 'ERROR_BUSINESS_EMAIL',
    },
    defaultError: {
        dataCy: 'generic-error',
        message: 'GENERIC_ERROR',
    },
};
