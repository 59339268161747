import { UiSignupLayoutRevamp } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import BottomPageOptions from '../../common/UiBackground/subcomponents/BottomPageOptions';
import SignupContainer from './subcomponents/SignupContainer';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        },
    }),
);

const SignupPageComponentRevamp: React.FC = () => {
    const classes = useStyles();

    return (
        <UiSignupLayoutRevamp>
            <div className={classes.container}>
                <div style={{ marginTop: '15vh' }} />
                <SignupContainer newSignupHeader />
                <BottomPageOptions languageSelectorOnly />
            </div>

        </UiSignupLayoutRevamp>
    );
};

export default SignupPageComponentRevamp;
