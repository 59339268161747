import React from 'react';

import {
    UiVerificationContainer,
    UiVerificationLayout,
} from '../../common/UiVerification';
import { VerificationFormComponent } from './subcomponents/VerificationFormComponent';

const VerificationPageComponent = () => (
    <UiVerificationLayout
        center={<UiVerificationContainer form={<VerificationFormComponent />} />}
        withLogo />
);

export default VerificationPageComponent;
