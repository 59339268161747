import { useMemo } from 'react';

import { useParams } from '../../container/Router';
import {
    PlatformName,
    useSignupType,
} from './SignupType';

export default function useIsBusiness() {

    const { isSignupType } = useSignupType();
    const params = useParams();

    const isAutomationHub = isSignupType(PlatformName.AutomationHub) || isSignupType(PlatformName.UiAutomationHub);
    return useMemo(() =>
        isSignupType(PlatformName.UiEnterprise) || (isAutomationHub && params.product_name === 'UiPath Automation Cloud')
    , [ isAutomationHub, isSignupType, params.product_name ]);
}
