import { UiText } from '@experiences/ui-common';
import LanguageIcon from '@mui/icons-material/Language';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
    useLocalization,
    useUpdateLocalization,
} from '../../../container/Localization';
import telemetryProvider from '../../../telemetry';
import {
    privacyLinks,
    termsLinks,
} from '../../../util/constants/LegalLinks';
import { useLocalizedLinks } from '../../../util/hooks/LocalizedLink';
import { useSupportedLanguages } from '../../../util/LanguageUtil';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 'auto',
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '4px',
        },
        languageIcon: {
            color: '#526069',
            marginRight: '5px',
        },
        links: {
            display: 'flex',
            alignItems: 'center',
        },
        select: {
            fontSize: '14px',
            paddingLeft: '8px',
        },
        defaultFontSize: { fontSize: '14px' },
        termsLink: {
            marginLeft: '24px',
            fontSize: '14px',
        },
        privacyLink: {
            marginLeft: '24px',
            fontSize: '14px',
        },
    }),
);

export const BottomPageOptions: React.FC<{ languageSelectorOnly?: boolean }> = ({ languageSelectorOnly = false }) => {
    const classes = useStyles();

    const language = useLocalization();
    const updateLanguage = useUpdateLocalization();
    const supportedLanguages = useSupportedLanguages();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();

    const changeLanguage = useCallback(
        (event: any) => {
            updateLanguage(event.target?.value);
            telemetryProvider.trackEvent({ name: 'BottomPage.ChangeLanguage' }, {
                ProductLanguageOld: language.toLowerCase(),
                ProductLanguageNew: event.target?.value,
            });
        },
        [ language, updateLanguage ],
    );

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <LanguageIcon
                    fontSize="small"
                    className={classes.languageIcon} />
                <TextField
                    select
                    id="languageOptions"
                    onChange={changeLanguage}
                    defaultValue="en"
                    SelectProps={{
                        disableUnderline: true,
                        classes: { select: classes.select },
                        inputProps: { 'aria-label': translate({ id: 'LANGUAGE_SELECTED' }, { 0: language.toUpperCase() }) },
                    }}
                    value={language?.toLowerCase()}
                    variant="standard"
                    data-cy="language-select"
                >
                    {supportedLanguages.map((option: { id: string; text: string }, i: number) => (
                        <MenuItem
                            id={option.id}
                            value={option.id}
                            key={i}
                            className={classes.defaultFontSize}
                            lang={option.id}
                            aria-label={`${option.text} ${option.id.toUpperCase()}`}
                        >
                            {option.text}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            {!languageSelectorOnly && <div className={classes.links}>
                <UiText className={classes.termsLink}>
                    <Link
                        href={getLocalizedLink(termsLinks)}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none">
                        {translate({ id: 'TERMS' })}
                    </Link>
                </UiText>
                <UiText className={classes.privacyLink}>
                    <Link
                        href={getLocalizedLink(privacyLinks)}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none">
                        {translate({ id: 'PRIVACY' })}
                    </Link>
                </UiText>
            </div>}
        </div>
    );
};

export default BottomPageOptions;
