import {
    ANNUAL_PLAN_TYPE,
    type IDirectBuyData,
} from '@experiences/ecommerce';
import { UiText } from '@experiences/ui-common';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { useLocalization } from '../../container/Localization';
import { getPrices } from '../../services/BillingService';
import { getOrganizationAppOriginFromEnv } from '../../util/ConfigUtil';
import { buildOrderSummary } from '../../util/ECommerceUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        summaryContainer: {
            borderRadius: '3px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.semantic.colorBorderDisabled,
            display: 'flex',
            flexDirection: 'column',
            margin: '16px 0',
            padding: '20px 15px',
        },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        packRow: {
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '14px',
        },
        planTypeTitle: {
            fontSize: '14px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        planTypeDescription: { color: theme.palette.semantic.colorForegroundDeEmp },
        centerLoader: { margin: 'auto' },
        emptyContainer: {
            margin: '16px 0',
            padding: '20px 15px',
        },
        percentageOff: {
            color: theme.palette.semantic.colorSuccessText,
            whiteSpace: 'pre',
        },
        savesPercentageOffText: {
            color: theme.palette.semantic.colorSuccessText,
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
        },
        priceText: {
            fontWeight: '400px',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundLight,
            textDecorationLine: 'line-through',
            marginRight: '4px',
        },
    }),
);

export const DirectBuyOrderSummaryComponent: React.FC<{
    directBuyData: IDirectBuyData;
}> = ({ directBuyData }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const language = useLocalization(true);
    const portalUrl = useMemo(() => getOrganizationAppOriginFromEnv(), []);

    const productConfigListRequest = useMemo<string[]>(() => Object.keys(directBuyData?.productQuantities ?? {}), [ directBuyData ]);
    const {
        data: prices, error: pricesError,
    } = useSWR([
        portalUrl,
        productConfigListRequest,
        directBuyData.currency,
    ], () => getPrices(portalUrl));

    const getPriceString = useCallback((price: number, currency: string) => `${(price / 100).toLocaleString(language, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`, [ language ]);

    const orderSummary = useMemo(() =>
        buildOrderSummary(
            prices,
            directBuyData,
        ),
    [
        prices,
        directBuyData,
    ]);

    return orderSummary
        ?
        <div
            className={classes.summaryContainer}
            data-cy='direct-buy-sku-preview'>
            <div className={classes.row}>
                <UiText className={classes.packRow}>
                    {translate({ id: `CLIENT_GENERIC_SKU_PACKAGE_PACK` })}
                </UiText>
                <UiText>
                    {directBuyData.commitmentType === ANNUAL_PLAN_TYPE ?
                        translate({ id: `CLIENT_PRICE_PER_YEAR` }, { price: getPriceString(orderSummary.price, orderSummary.currency) })
                        :
                        translate({ id: `CLIENT_PRICE_PER_MONTH` }, { price: getPriceString(orderSummary.price, orderSummary.currency) })}
                </UiText>
            </div>

            <UiText className={classes.planTypeTitle}>
                {translate({ id: 'CLIENT_COMMITMENT_TYPE' })}
            </UiText>
            <UiText className={classes.planTypeDescription}>
                {translate({ id: `CLIENT_${directBuyData.commitmentType}_PLAN` })}
            </UiText>
        </div>
        :
        (pricesError
            ?
            (<div className={classes.emptyContainer} />)
            : (<div className={classes.centerLoader}>
                <CircularProgress />
            </div>)
        );
};
