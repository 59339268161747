import { UiText } from '@experiences/ui-common';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import UiPassword from '../../common/UiPassword/UiPassword';
import { UiProgressButton } from '../../common/UiProgressButton/UiProgressButton';
import { useRouter } from '../../container/Router';
import useAuthInstance from '../../util/hooks/AuthInstance';
import { useIsCustomerPortal } from '../../util/hooks/IsCustomerPortal';
import Providers from '../../util/interfaces/provider';

const useStyles = makeStyles(() =>
    createStyles({
        error: {
            display: 'flex',
            margin: '4px 0',
            alignSelf: 'flex-center',
            alignItems: 'center',
        },
        errorIcon: {
            marginRight: '4px',
            transform: 'scale(.8)',
        },
        errorText: { fontSize: '14px' },
        textField: { marginTop: '16px' },
        form: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        link: {
            fontSize: '14px',
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        forgotPassword: {
            alignSelf: 'flex-end',
            margin: '4px 0 12px 0 ',
        },
        noHeightHidden: {
            height: '0px',
            overflow: 'hidden',
            opacity: 0,
            visibility: 'hidden',
        },
        formContainer: {
            height: '250px',
            opacity: 1,
            width: '100%',
            transition: 'height 400ms 100ms ease, opacity 300ms 100ms ease',
            visibility: 'visible',
        },
        singleFormContainer: {
            height: '160px',
            width: '100%',
            visibility: 'visible',
        },
    }),
);

interface ILogin {
    email?: string;
    password: string;
}

export const LoginFormComponent: React.FC<{
    showForm: boolean;
    showEmailField: boolean;
    lastUsedEmail: string;
    lastUsedMethod: string;
}> = ({
    showForm, showEmailField, lastUsedEmail, lastUsedMethod,
}) => {
    const classes = useStyles();
    const nextRoute = useRouter();
    const { formatMessage: translate } = useIntl();

    const getAuthInstance = useAuthInstance();

    const [ loading, setLoading ] = useState(false);

    const isCustomerPortal = useIsCustomerPortal();

    const {
        setError,
        clearErrors,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useFormContext<ILogin>();

    const login = useCallback(
        (data: ILogin) => {
            setLoading(true);
            getAuthInstance().basicLogin(data.email ? data.email : lastUsedEmail, data.password, err => {
                switch (err?.code) {
                    case 'access_denied':
                    case 'invalid_user_password':
                        setError('email', {
                            type: 'invalid',
                            message: 'INVALID_EMAIL_PASSWORD',
                        });
                        setError('password', { type: 'invalid' });
                        setValue('password', '');
                        break;
                    case 'too_many_attempts':
                        setError('email', {
                            type: 'invalid',
                            message: 'MULTIPLE_FAILED_LOGIN_ATTEMPT',
                        });
                        setError('password', { type: 'invalid' });
                        setValue('password', '');
                        break;
                    default:
                        setError('email', {
                            type: 'default_error',
                            message: 'GENERIC_ERROR_CLOSE_TAB',
                        });
                }
                setLoading(false);
            });
        },
        [ getAuthInstance, lastUsedEmail, setError, setValue ],
    );

    return (
        <>
            {errors?.email?.message && (
                <span
                    className={classes.error}
                    data-cy="login-error">
                    <PortalAlertBar
                        status='error'
                        cancelable={false}
                        theme="light"
                    >
                        {translate({ id: errors.email.message.toString() })}
                    </PortalAlertBar>
                </span>
            )}
            <div
                className={clsx({
                    [classes.noHeightHidden]: showEmailField || (!showEmailField && lastUsedMethod !== Providers.Email),
                    [classes.singleFormContainer]: !showEmailField && lastUsedMethod === Providers.Email,
                    [classes.formContainer]: (!showEmailField && lastUsedMethod === Providers.Email) || showForm,
                })}
            >
                <form
                    onSubmit={handleSubmit(login)}
                    className={classes.form}>
                    {showEmailField && (
                        <Controller
                            control={control}
                            name="email"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    error={!!errors.email}
                                    label={!isCustomerPortal ? translate({ id: 'EMAIL' }) : translate({ id: 'EMAIL_BUSINESS_V2' })}
                                    InputLabelProps={{ id: 'emailLabel' }}
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        'data-cy': 'login-email-input',
                                        'aria-labelledby': 'emailLabel',
                                    }}
                                />
                            )}
                        />
                    )}
                    <UiPassword
                        control={control}
                        name="password"
                        className={clsx({ [classes.textField]: showEmailField })}
                        customValidation={{
                            rules: { required: true },
                            error: !!errors.password,
                        }}
                        dataCy="login-password-input"
                    />
                    <div className={classes.forgotPassword}>
                        <UiText
                            className={classes.link}
                            onClick={() => nextRoute({ type: 'reset_password' })}
                            data-cy="forgot-password-button"
                            tabIndex={0}>
                            {translate({ id: 'FORGOT_PASSWORD' })}
                        </UiText>
                    </div>
                    <UiProgressButton
                        loading={loading}
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={() => clearErrors()}
                        data-cy="login-button"
                    >
                        {translate({ id: 'SIGN_IN' })}
                    </UiProgressButton>
                </form>
            </div>
        </>
    );
};
