import { UiSignupLayout } from '@experiences/ui-common';
import React from 'react';

import BottomPageOptions from '../../common/UiBackground/subcomponents/BottomPageOptions';
import { useSignupScreenExperiment } from '../../util/ExperimentUtil';
import useIsDirectBuy from '../../util/hooks/DirectBuy';
import { useIsLegacyForm } from '../../util/hooks/IsLegacyForm';
import useIsBusiness from '../../util/hooks/useIsBusiness';
import DirectBuyContainer from './subcomponents/DirectBuyContainer';
import LeftContainer from './subcomponents/LeftContainer';
import SignupContainer from './subcomponents/SignupContainer';

const SignupPageComponent = () => {
    const isDirectBuyFlow = useIsDirectBuy();
    const isBusiness = useIsBusiness();
    const isLegacyForm = useIsLegacyForm();

    const isExperimentActive = useSignupScreenExperiment();

    return <UiSignupLayout
        left={<LeftContainer />}
        right={isDirectBuyFlow ? <DirectBuyContainer /> : <SignupContainer
            isLegacyForm={isLegacyForm}
            isBusiness={isBusiness} />}
        showLeftContainer={!isDirectBuyFlow}
        flexRatio={isExperimentActive ? {
            left: 2,
            right: 3,
        } : undefined}
        bottomOptions={<BottomPageOptions languageSelectorOnly />} />;
};

export default SignupPageComponent;
