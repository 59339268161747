import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens, { FontVariantToken } from '@uipath/apollo-core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { UiBackgroundSignup } from '../../../../common/UiBackground/UiBackgroundSignup';
import { LeftColor } from '../../../../common/UiBackground/UiBackgroundV3';
import UiList from '../../../../common/UiList/UiList';
import { useLocalization } from '../../../../container/Localization';
import {
    logAccessPage,
    logClick,
} from '../../../../telemetry';
import palette from '../../../../theme/palette';
import { useSignupScreenExperiment } from '../../../../util/ExperimentUtil';
import { useRegister } from '../../../../util/hooks/Register';
import getLinks from '../util/Links';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        title: {
            color: palette.light.title.primary,
            width: '100%',
            flexShrink: 0,
            fontSize: '32px',
            lineHeight: '32px',
            fontWeight: 500,
            fontFamily: 'Poppins',
            marginBottom: '38px',
        },
        titleName: { color: palette.light.title.tertiary },
        section: {
            marginTop: '32px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flexShrink: 0,
        },
        listHeader: {
            color: palette.light.list.header,
            marginBottom: '16px',
            fontWeight: 700,
        },
        header: {
            color: palette.light.subtitle,
            fontSize: '14px',
            fontWeight: 600,
        },
        link: {
            zIndex: 1,
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            marginTop: '8px',
            display: 'table',
            cursor: 'pointer',
            color: palette.light.link,
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
        },
        list: {
            marginTop: '24px',
            width: '100%',
            flexShrink: 0,
        },
        trademark: {
            fontWeight: 400,
            fontSize: '8px',
            position: 'relative',
            top: '-2.5em',
        },
        proTrialLink: {
            fontWeight: 600,
            zIndex: 1,
        },
        learnMoreLink: {
            zIndex: 1,
            marginTop: '4px',
            fontSize: '12px',
        },
        linkUnderline: {
            textDecoration: 'none',
            '&:hover': { textDecoration: 'underline' },
        },
        titleExp: {
            fontFamily: Tokens.FontFamily.FontHeader2BoldFamily,
            fontWeight: Tokens.FontFamily.FontWeightBold,
            fontSize: Tokens.FontFamily.FontHeader2BoldSize,
            lineHeight: '48px',
        },
        titleNameExp: { color: palette.backgroundBlue.secondary },
        headerExp: {
            fontFamily: Tokens.FontFamily.FontHeader2BoldFamily,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
            fontSize: Tokens.FontFamily.FontBrandLSize,
            lineHeight: '28px',
        },
    }),
}));

const AutomationCloudForEnterprise = ({ source }: { source?: string }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const register = useRegister();

    const language = useLocalization();

    const links = useMemo(() => getLinks(language), [ language ]);

    const isExperimentActive = useSignupScreenExperiment();

    useMemo(() => {
        if (source) {
            logAccessPage('trial', source);
        }
    }, [ source ]);

    const items = useMemo(() =>
        [
            [
                'PRO_TRIAL_ROBOTS_ENABLE',
                'SUPPORT_24_7',
                'UPTIME_GUARANTEE',
                'MULTIPLE_TENANTS',
            ],
            [
                'MULTI_TENANCY_UPO_TO_THREE',
                'DATA_SOVEREIGNTY',
                'SUPPORT_24_7_FROM_UI_PATH',
                'ALWAYS_AVAILABLE',
            ],
            [],
        ]
    , [ ]);

    return (
        <UiBackgroundSignup
            isExperiment={isExperimentActive}
            color={LeftColor.Blue}>
            <UiText
                className={isExperimentActive ? classes.titleExp : classes.title}
                data-cy="automation-cloud-trial-title">
                <FormattedMessage
                    id={(source === 'BDR') ? 'AC_PRO_HEADER' : 'AC_ENTERPRISE_HEADER'}
                    description="Welcome Message"
                    values={{
                        // eslint-disable-next-line react/no-unstable-nested-components
                        title: (chunks: React.ReactNode[]) => (
                            <span className={isExperimentActive ? classes.titleNameExp : classes.titleName}>
                                {chunks}
                            </span>
                        ),
                        // eslint-disable-next-line react/no-unstable-nested-components
                        tm: (chunks: React.ReactNode[]) => <span className={classes.trademark}>
                            {chunks}
                        </span>,
                    }}
                />
            </UiText>
            {!isExperimentActive && <div className={classes.list}>
                <UiText
                    role="heading"
                    aria-level={6}
                    variant={FontVariantToken.fontSizeMBold}
                    className={classes.listHeader}>
                    {translate({ id: (source === 'BDR') ? 'CLOUD_FULL_CAPABILITIES_OFFER_PRO_BDR' : 'CLOUD_FULL_CAPABILITIES_OFFER_PRO' })}
                </UiText>
                <UiList
                    items={(source === 'BDR') ? items[1] : items[0]}
                    color={palette.light.list.body}
                    colorListItemCheckmark="green" />
            </div>}
            {(source !== 'BDR') &&
                <div>
                    {!isExperimentActive && <div className={classes.list}>
                        <UiText className={classes.proTrialLink}>
                            <FormattedMessage
                                id="PRO_TRIAL_OLD_SIGN_UP_PLAN"
                                values={{
                                    a: (msg: any) =>
                                        (
                                            <a
                                                className={clsx(classes.a, classes.linkUnderline)}
                                                href="https://www.uipath.com/pricing#comparison"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {msg}
                                            </a>
                                        ) as any,
                                }}
                            />
                        </UiText>
                    </div>}

                    <div className={classes.section}>
                        <UiText className={isExperimentActive ? classes.headerExp : classes.header}>
                            {translate({ id: 'ARE_YOU_SMALL_BUSINESS' })}
                        </UiText>
                        <Link
                            onClick={async () => {
                                logClick(AutomationCloudForEnterprise.name, false, 'TRY_AUTOMATION_CLOUD');
                                register({ subscriptionPlan: 'community' });
                            }}
                            data-cy="get-automation-cloud-link"
                        >
                            <UiText className={classes.link}>
                                {translate({ id: 'GET_AUTOMATION_COMMUNITY' })}
                            </UiText>
                        </Link>
                        <UiText className={classes.learnMoreLink}>
                            <FormattedMessage
                                id="PRO_TRIAL_LEARN_MORE_COMMUNITY"
                                values={{
                                    a: (msg: any) =>
                                        (
                                            <a
                                                className={clsx(classes.a, classes.linkUnderline)}
                                                href="https://www.uipath.com/legal/trust-and-security/legal-terms"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                data-cy="learn-more-community-link"
                                            >
                                                {msg}
                                            </a>
                                        ) as any,
                                }}
                            />
                        </UiText>
                    </div>

                    <div className={classes.section}>
                        <UiText className={isExperimentActive ? classes.headerExp : classes.header}>
                            {translate({ id: 'PREFER_ONPREM' })}
                        </UiText>
                        <Link
                            href={links.enterpriseServer}
                            onClick={() => logClick(AutomationCloudForEnterprise.name, false, links.enterpriseServer)}
                            data-cy="on-prem-solution-link"
                        >
                            <UiText className={classes.link}>
                                {translate({ id: 'TRY_ENTERPRISE_ON_PREM' })}
                            </UiText>
                        </Link>
                    </div>
                </div>}
        </UiBackgroundSignup>
    );
};

export default AutomationCloudForEnterprise;
