import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import { UiBackground } from '../../../../common/UiBackground/UiBackground';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        title: {
            fontWeight: 500,
            fontSize: '24px',
            flexShrink: 0,
        },
        image: {
            marginTop: '24px',
            alignSelf: 'center',
        },
    }),
);

const DefaultContent = () => {
    const classes = useStyles();

    const { formatMessage: translate } = useIntl();

    return (
        <UiBackground>
            <div className={classes.root}>
                <UiText
                    align="center"
                    className={classes.title}
                    data-cy="default-content-title">
                    {translate({ id: 'ONE_ACCOUNT_UIPATH' })}
                </UiText>
                <img
                    src="https://platform-cdn.uipath.com/auth0-login/oneAccountAllProduct.svg"
                    alt={translate({ id: 'ONE_ACCOUNT_ALT' })}
                    className={classes.image}
                />
            </div>
        </UiBackground>
    );
};

export default DefaultContent;
