import { useMemo } from 'react';
import { Statsig } from 'statsig-react';

import FreeSkuSignupPageComponent from '../components/FreeSkuPageComponent/FreeSkuSignupPageComponent';
import InvitePageComponent from '../components/InvitePageComponent/InvitePageComponent';
import SigninComponent from '../components/LoginPageComponent/SigninComponent';
import SignupPageComponent from '../components/SignupPageComponent/SignupPageComponent';
import SignupPageComponentRevamp from '../components/SignupPageComponent/SignupPageComponentRevamp';
import VerificationPageComponent from '../components/VerificationPageComponent/VerificationPageComponent';
import VerificationPageComponentRevamp from '../components/VerificationPageComponent/VerificationPageComponentRevamp';
import useIsDirectBuy from '../util/hooks/DirectBuy';
import { useFreeSku } from '../util/hooks/FreeSku';
import { PlatformName } from '../util/hooks/SignupType';
import useIsSignupWithText from '../util/hooks/useIsSignupWithText';
import { FreeSkuRegister } from './FreeSku';
import { useParams } from './Router';

const OffersRevampPlatforms = [
    PlatformName.UiEnterprise,
    PlatformName.UiPlatform,
    PlatformName.UiCommunity,
    PlatformName.UiMarketPlace,
    PlatformName.UiFree,
];

export default function usePage() {
    const {
        type, user_invite, platform_name,
    } = useParams();
    const {
        isFreeSkuRegister, isFreeSkuCallback,
    } = useFreeSku();
    const isSignUpWithText = useIsSignupWithText();

    const isDirectBuyFlow = useIsDirectBuy();

    // TODO: when directbuy changes are made for offers revamp, we can remove the condition from here
    const renderOffersRevampSignup = useMemo(() => !isDirectBuyFlow &&
        (OffersRevampPlatforms.includes(platform_name) || type === 'email_verification'),
    [ isDirectBuyFlow, platform_name, type ]);

    const PageComponent = useMemo(() => {
        if (isFreeSkuRegister) {
            return FreeSkuRegister;
        }

        if (isFreeSkuCallback) {
            return FreeSkuSignupPageComponent;
        }

        if (user_invite === 'true' && type === 'signup') {
            return InvitePageComponent;
        }

        if (type === 'signup') {
            Statsig.logEvent('signup_page_view');

            if (isSignUpWithText) {
                return SignupPageComponent;
            }
            return renderOffersRevampSignup ? SignupPageComponentRevamp : SignupPageComponent;
        }

        if (type === 'email_verification') {
            return renderOffersRevampSignup ? VerificationPageComponentRevamp : VerificationPageComponent;
        }

        return SigninComponent ;
    }, [
        isFreeSkuRegister,
        isFreeSkuCallback,
        user_invite,
        type,
        isSignUpWithText,
        renderOffersRevampSignup,
    ]);

    return PageComponent;
}
