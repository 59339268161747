import {
    UiDivider,
    UiLogo,
    UiText,
} from '@experiences/ui-common';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { UiBackgroundLogin } from '../../common/UiBackground/UiBackgroundLogin';
import UiProviderButton from '../../common/UiProviderButton/UiProviderButton';
import { UiSocialButton } from '../../common/UiSocialButton/UiSocialButton';
import {
    useParams,
    useRouter,
} from '../../container/Router';
import palette from '../../theme/palette';
import { getOrganizationAppOriginFromEnv } from '../../util/ConfigUtil';
import {
    getLastUsedLoginEmail,
    getLastUsedLoginEnterpriseSsoOrg,
    getLastUsedLoginMethod,
} from '../../util/CookieUtil';
import Providers from '../../util/interfaces/provider';
import EnterpriseSsoLoginComponent from './EnterpriseSsoLogin/EnterpriseSsoLoginComponent';
import { redirectToSsoRetryUrl } from './EnterpriseSsoLogin/EnterpriseSsoLoginHelper';
import { LoginFooterComponent } from './LoginFooterComponent';
import { LoginFormComponent } from './LoginFormComponent';
import { LoginProfileComponent } from './LoginProfileComponent';
import ResetPasswordComponent from './ResetPasswordComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        center: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 4,
            justifyContent: 'center',
            alignSelf: 'center',
        },
        paperDefault: {
            width: 450,
            flexShrink: 0,
        },
        container: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 30px 20px',
            transition: 'height 400ms 000ms ease',
        },
        containerOptionsWithForm: { height: 'auto' },
        logo: { marginTop: '40px' },
        login: {
            marginTop: '16px',
            fontSize: '20px',
            fontWeight: 650,
            color: theme.palette.semantic.colorForeground,
        },
        account: {
            marginTop: '10px',
            fontSize: '30px',
            fontWeight: 400,
        },
        orgUrl: {
            fontSize: '12px',
            fontWeight: 400,
            color: '#526069',
        },
        lastUsed: {
            paddingTop: '30px',
            flexShrink: 0,
            width: '100%',
            opacity: 1,
            overflow: 'hidden',
            visibility: 'visible',
        },
        socialAuth: {
            paddingTop: '18px',
            flexShrink: 0,
            width: '100%',
            opacity: 1,
            transition: 'height 150ms 000ms ease-in, opacity 300ms 100ms ease-in',
            visibility: 'visible',
            marginTop: '15px',
        },
        socialButton: {
            marginBottom: '16px',
            borderColor: `${theme.palette.semantic.colorBorderDeEmp} !important`,
        },
        singleSocialButton: { marginBottom: '13px' },
        link: {
            fontSize: '14px',
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        emailButtonContainer: {
            width: '100%',
            opacity: 1,
            visibility: 'visible',
        },
        ssoButtonContainer: {
            width: '100%',
            opacity: 1,
            transition: 'height 100ms 000ms ease-in, opacity 400ms 000ms ease-in',
            visibility: 'visible',
        },
        emailButton: {
            margin: '16px 0 16px 0',
            borderColor: `${theme.palette.semantic.colorBorderDeEmp} !important`,
        },
        ssoButton: { marginBottom: '16px' },
        singleEmailButton: {
            marginTop: '10px',
            width: '390px',
        },
        noHeightHidden: {
            height: '0px',
            overflow: 'hidden',
            opacity: 0,
            transition: 'height 300ms 100ms ease, opacity 300ms 100ms ease',
            visibility: 'hidden',
        },
        signInHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    }),
);

interface ILogin {
    email?: string;
    password: string;
}

const SigninComponent = () => {
    const classes = useStyles();
    const params = useParams();
    const nextRoute = useRouter();
    const { formatMessage: translate } = useIntl();

    const [ showForm, setShowForm ] = useState(false);
    const [ showOptions, setShowOptions ] = useState(false);
    const isUserInvite = useMemo(() => (params.user_invite === 'true' ? true : false), [ params ]);

    const portalUrl = useMemo(() => getOrganizationAppOriginFromEnv(), []);
    const lastUsedMethod = useMemo(() => getLastUsedLoginMethod(params.hide_linkedin, !params.retryUrl), [ params ]);
    const lastUsedEmail = useMemo(() => getLastUsedLoginEmail(), []);
    const lastUsedOrg = useMemo(() => getLastUsedLoginEnterpriseSsoOrg(), []);

    const methods = useForm<ILogin>({
        mode: 'onSubmit',
        defaultValues: {
            email: '',
            password: '',
        },
    });

    useEffect(
        () =>
            setShowOptions(!lastUsedEmail || !lastUsedMethod || (lastUsedMethod === Providers.EnterpriseSSO && !lastUsedOrg)),
        [ lastUsedEmail, lastUsedMethod, lastUsedOrg ],
    );

    const enterpriseSsoButton = useMemo(
        () => (
            <UiSocialButton
                icon={<VerifiedUserOutlinedIcon
                    fontSize="small"
                    color="action" />}
                fullWidth
                centered
                className={clsx(classes.socialButton, {
                    [classes.singleSocialButton]: !showOptions && lastUsedMethod !== Providers.EnterpriseSSO,
                    [classes.ssoButton]: showForm,
                })}
                onClick={() =>
                    !showOptions && lastUsedOrg
                        ? redirectToSsoRetryUrl(portalUrl, params.retryUrl, lastUsedOrg)
                        : nextRoute({ type: 'enterprise_sso' })}
                data-cy="authorize-with-sso"
            >
                {translate({ id: 'CONTINUE_WITH_ENTERPRISE_SSO' })}
            </UiSocialButton>
        ),
        [ classes, lastUsedMethod, showForm, showOptions, translate, nextRoute, lastUsedOrg, params, portalUrl ],
    );

    const LoginProfileProvider = useMemo(() => {
        if (lastUsedMethod === Providers.EnterpriseSSO) {
            return enterpriseSsoButton;
        }

        return lastUsedMethod !== Providers.Email ? (
            <UiProviderButton
                centered
                provider={lastUsedMethod}
                showOptions={showOptions} />
        ) : undefined;
    }, [ showOptions, enterpriseSsoButton, lastUsedMethod ]);

    return (
        <UiBackgroundLogin
            bottomOptions
            backgroundColor={palette.light.backgroundNew}>
            <div className={classes.center}>
                <Paper className={classes.paperDefault}>
                    <div
                        className={clsx(classes.container, { [classes.containerOptionsWithForm]: showOptions && showForm })}
                    >
                        {params.type === 'reset_password' ? (
                            <ResetPasswordComponent />
                        ) : params.retryUrl && params.type === 'enterprise_sso' ? (
                            <EnterpriseSsoLoginComponent retryUrl={params.retryUrl} />
                        ) : (
                            <>
                                <UiLogo
                                    className={classes.logo}
                                    data-cy='uipath-logo' />
                                <div className={classes.signInHeader}>
                                    <UiText
                                        className={classes.login}
                                        role="heading"
                                        aria-level={1}>
                                        {translate({ id: 'SIGN_IN_NEW' })}
                                    </UiText>
                                </div>
                                {lastUsedMethod === Providers.EnterpriseSSO && (
                                    <div
                                        className={clsx({ [classes.noHeightHidden]: showOptions })}
                                        data-cy="organization-url"
                                    >
                                        <UiText className={classes.orgUrl}>
                                            {`${portalUrl}/${lastUsedOrg}`}
                                        </UiText>
                                    </div>
                                )}
                                <div
                                    className={clsx({
                                        [classes.lastUsed]: !showOptions,
                                        [classes.noHeightHidden]: showOptions,
                                    })}
                                >
                                    <LoginProfileComponent
                                        email={lastUsedEmail}
                                        provider={LoginProfileProvider} />
                                </div>

                                <div
                                    className={clsx({
                                        [classes.noHeightHidden]: !showOptions,
                                        [classes.socialAuth]: showOptions,
                                    })}
                                >
                                    {[ Providers.Google, Providers.Microsoft, Providers.LinkedIn ]
                                        .filter((provider) => (params.hide_linkedin ? provider !== Providers.LinkedIn : true))
                                        .map((provider, index) => (
                                            <UiProviderButton
                                                key={index}
                                                centered
                                                provider={provider}
                                                showOptions={showOptions} />
                                        ))}
                                    {params.retryUrl && (
                                        <div
                                            className={clsx({
                                                [classes.ssoButtonContainer]: showOptions,
                                                [classes.noHeightHidden]: !showOptions,
                                            })}
                                        >
                                            {isUserInvite ? <></> : enterpriseSsoButton}
                                        </div>
                                    )}
                                    <UiDivider />
                                </div>
                                <Collapse
                                    in={!showForm && showOptions}
                                    className={clsx({ [classes.emailButtonContainer]: showOptions })}>
                                    <UiSocialButton
                                        className={clsx(classes.emailButton, { [classes.singleEmailButton]: !showOptions })}
                                        icon={<MailOutlineIcon
                                            fontSize="small"
                                            color="action" />}
                                        fullWidth
                                        centered
                                        onClick={() => setShowForm(true)}
                                        data-cy="authorize-with-email"
                                    >
                                        {translate({ id: 'CONTINUE_WITH_EMAIL' })}
                                    </UiSocialButton>
                                </Collapse>
                                <FormProvider {...methods}>
                                    <LoginFormComponent
                                        showForm={showForm}
                                        showEmailField={showOptions}
                                        lastUsedEmail={lastUsedEmail}
                                        lastUsedMethod={lastUsedMethod}
                                    />
                                    <LoginFooterComponent
                                        showOptionsFooter={showOptions}
                                        setShowOptions={setShowOptions}
                                    />
                                </FormProvider>
                            </>
                        )}
                    </div>
                </Paper>
            </div>
        </UiBackgroundLogin>
    );
};

export default SigninComponent;
