import { useMemo } from 'react';

import { useParams } from '../../container/Router';
import Providers from '../interfaces/provider';

const InviteExclusiveProviders = [ Providers.Google, Providers.Microsoft ];

export default function useIsProviderInvite() {
    const params = useParams();

    const isProviderInvite = useMemo(
        () =>
            !!params.login_hint && !!params.login_provider && InviteExclusiveProviders.indexOf(params.login_provider) > -1,
        [ params ],
    );

    return isProviderInvite;
}
