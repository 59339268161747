import { UiText } from '@experiences/ui-common';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useDebounce } from 'use-debounce';

import { useLocalization } from '../../container/Localization';
import { useParams } from '../../container/Router';
import { logSignupSubmit } from '../../telemetry';
import { termsLinks } from '../../util/constants/LegalLinks';
import { validateEmail } from '../../util/EmailUtil';
import { useSignupScreenExperiment } from '../../util/ExperimentUtil';
import useAuthInstance from '../../util/hooks/AuthInstance';
import { useLocalizedLinks } from '../../util/hooks/LocalizedLink';
import { PlatformName } from '../../util/hooks/SignupType';
import useIsSignupWithText from '../../util/hooks/useIsSignupWithText';
import UiActionErrorMessage from '../UiActionErrorMessage/UiActionErrorMessage';
import { UiEmail } from '../UiEmail';
import UiPassword from '../UiPassword/UiPassword';
import { UiProgressButton } from '../UiProgressButton/UiProgressButton';
import { AuthErrorMap } from './AuthError';

const useStyles = makeStyles(theme =>
    createStyles({
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        standardMargin: { marginTop: '16px' },
        standardWidth: { width: '360px' },
        spacer: { margin: '16px 0' },
        communityWarning: {
            background: theme.palette.semantic.colorSecondaryFocused,
            borderRadius: '3px',
            padding: '8px 12px',
            fontFamily: 'Noto Sans',
            fontSize: '12px',
        },
        link: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontFamily: 'Noto Sans',
            fontWeight: 600,
            fontSize: '12px',
        },
    }),
);

interface ICollapseProps {
    collapseCondition: boolean;
    setCollapseCondition: (_: boolean) => void;
}

interface ISimpleSignupFormProps {
    collapseProps?: ICollapseProps;
    isBusiness?: boolean;
    isRevamp?: boolean;
}

export interface ISimpleSignupData {
    email: string;
    password: string;
}

export const SimpleSignupFormComponent = ({
    collapseProps = undefined, isBusiness = false, isRevamp = false,
}: ISimpleSignupFormProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const getAuthInstance = useAuthInstance();
    const isSignUpWithText = useIsSignupWithText();

    const isExperimentActive = useSignupScreenExperiment();

    const {
        platform_name, client, login_hint, user_invite, subscription_plan,
    } = useParams();
    const getLocalizedLink = useLocalizedLinks();
    const language = useLocalization(true);

    const [ loading, setLoading ] = useState(false);
    const [ renderCommunityWarning, setRenderCommunityWarning ] = useState(false);

    const {
        handleSubmit,
        setError,
        setValue,
        control,
        watch,
        formState: {
            isValid, errors,
        },
    } = useForm<ISimpleSignupData>({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: {
            email: login_hint ?? '',
            password: '',
        },
    });

    const email = watch('email');

    const allEmailsPlatforms = useMemo(() =>
        [ PlatformName.UiFree, PlatformName.UiAutomationCloud, PlatformName.UiCommunity, PlatformName.UiPlatform ].includes(platform_name),
    [ platform_name ]);

    const validateBusinessEmail = useCallback(async (emailToValidate: string) => {
        const isBusinessEmail = await validateEmail(emailToValidate, true);
        setRenderCommunityWarning(!isBusinessEmail && !isSignUpWithText);
    }, [ isSignUpWithText ]);

    const [ debouncedEmail ] = useDebounce(email, 500);

    useEffect(() => {
        if (isRevamp && debouncedEmail && !isSignUpWithText && !allEmailsPlatforms && !user_invite) {
            validateBusinessEmail(debouncedEmail);
        }
    }, [ allEmailsPlatforms, debouncedEmail, isRevamp, isSignUpWithText, validateBusinessEmail, user_invite ]);

    useEffect(() => {
        const hasEmail = email.length > 0;
        if (collapseProps && collapseProps.collapseCondition !== hasEmail) {
            collapseProps.setCollapseCondition(hasEmail);
        }
        if (isRevamp && !hasEmail) {
            setRenderCommunityWarning(false);
        }
    }, [ collapseProps, email, isRevamp ]);

    const onSubmit = useCallback(
        async (data: ISimpleSignupData) => {
            setLoading(true);

            setRenderCommunityWarning(false);

            const payload = {
                ...data,
                language,
                platformName: platform_name,
                isBusinessEmail: false,
                client,
            };

            if (isBusiness || isRevamp) {
                const isBusinessEmail = await validateEmail(payload.email);
                if (isBusiness && !isBusinessEmail) {
                    setError('email', { type: 'invalidDomain' });
                    setLoading(false);
                    return;
                }

                payload.isBusinessEmail = isBusinessEmail;
            }

            logSignupSubmit(subscription_plan);

            getAuthInstance().simpleSignupDB(payload, true, (err) => {
                let errorCode = 'defaultError';
                switch (err?.code) {
                    case 'user_exists':
                        errorCode = 'userExists';
                        break;
                    case 'server_error':
                    case 'extensibility_error':
                        errorCode = 'useSso';
                        break;
                    default: break;
                }
                setError('email', { type: errorCode });
                setLoading(false);
            });
        },
        [
            language,
            platform_name,
            client,
            isBusiness,
            isRevamp,
            subscription_plan,
            getAuthInstance,
            setError,
        ],
    );

    const renderBoldText = useCallback((chunk: React.ReactNode[]) => <b>
        {chunk}
    </b>, []);

    // Needed for Invite User flow
    const userInviteError = useMemo(() => errors?.email?.type ? AuthErrorMap[errors.email.type] : undefined, [ errors?.email?.type ]);

    return (
        <form
            className={classes.flex}
            onSubmit={handleSubmit(onSubmit)}>
            <UiEmail
                control={control}
                name="email"
                errors={errors}
                disabled={!!user_invite}
                InputLabelProps={{ id: 'emailLabel' }}
                inputProps={{
                    className: user_invite ? 'Mui-disabled' : '',
                    'aria-labelledby': 'emailLabel',
                }}
                label={translate({ id: `EMAIL${isBusiness ? '_BUSINESS' : ''}` })}
                data-cy="signup-form-email-field"
            />
            {!!user_invite && errors.email && <FormHelperText error={!!errors}>
                {userInviteError ? <UiActionErrorMessage error={userInviteError} /> : undefined}
            </FormHelperText>}
            <Collapse in={!!email && renderCommunityWarning}>
                <div className={classes.communityWarning}>
                    <FormattedMessage
                        id="OFFERS_REVAMP_COMMUNITY_WARNING"
                        values={{
                            b: renderBoldText,
                            CHANGE: <UiText
                                display="inline"
                                className={classes.link}
                                onClick={() => {
                                    setRenderCommunityWarning(false);
                                    setValue('email', '');
                                }}>
                                {translate({ id: 'OFFERS_REVAMP_COMMUNITY_CHANGE' })}
                            </UiText>,
                        }}
                    />
                </div>

            </Collapse>
            <Collapse in={collapseProps?.collapseCondition ?? true}>
                <UiPassword
                    control={control}
                    name="password"
                    className={classes.standardMargin}
                    useDefaultValidation
                    dataCy="signup-form-password-field"
                />
            </Collapse>
            {isRevamp
                ? <div className={classes.spacer} />
                : <FormHelperText className={clsx(classes.spacer, classes.standardWidth)}>
                    {translate(
                        { id: 'SIMPLE_SIGNUP_LEGAL_TERMS' },
                        {
                            TERMS: (
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={getLocalizedLink(termsLinks)}
                                    underline="none">
                                    {translate({ id: 'TERMS_AND_CONDITIONS_SIMPLE' })}
                                </Link>
                            ),
                        },
                    )}
                </FormHelperText>}
            <UiProgressButton
                variant="contained"
                color="primary"
                type="submit"
                onMouseDown={(e: any) => e.preventDefault()}
                loading={loading}
                disabled={!isValid}
                data-cy="signup-form-submit-button"
                fullWidth
            >
                {translate({
                    id: isExperimentActive ? 'SIGNUP_EMAIL' :
                        isRevamp ? 'OFFERS_REVAMP_SIGNUP_SUBMIT' : `CONTINUE_WITH_EMAIL${isBusiness ? '_BUSINESS' : ''}`,
                })}
            </UiProgressButton>
            {isRevamp && <FormHelperText className={clsx(classes.spacer, classes.standardWidth)}>
                {translate(
                    { id: 'OFFERS_REVAMP_PRIVACY_POLICY' },
                    {
                        TERMS: (
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={getLocalizedLink(termsLinks)}
                                underline="none">
                                {translate({ id: 'TERMS_AND_CONDITIONS_SIMPLE' })}
                            </Link>
                        ),
                    },
                )}
            </FormHelperText>}
        </form>
    );
};
