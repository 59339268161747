import {
    getCachedLanguage as getCookieLanguage,
    setLanguage as setCookieLanguage,
} from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import getEnvironment from '../util/EnvironmentUtil';
import {
    Features,
    useFeatureFlagValue,
} from '../util/FeatureFlagUtil';
import type { LanguageCulture } from '../util/LanguageUtil';
import { isValidLanguageCode } from '../util/LanguageUtil';

const env = getEnvironment();

export const LocalizationContext = React.createContext<{ language: string; updateLanguage: (language: LanguageCulture) => void }>(
    {
        language: getCookieLanguage(env) ?? 'en',
        updateLanguage: () => {},
    },
);

export const useLocalization = (useStrict = false) => {
    const { language } = useContext(LocalizationContext);
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);
    const sanitizedLanguage = useStrict ? isValidLanguageCode(language) ?? 'en' : language;
    return (
        (!EnableLocKeysLanguage || useStrict) && sanitizedLanguage?.toLowerCase() === 'keys'
            ? 'en'
            : sanitizedLanguage
    ) as LanguageCulture;
};

export const useUpdateLocalization = () => {
    const { updateLanguage } = useContext(LocalizationContext);
    return updateLanguage;
};

export const LocalizationContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);

    const [ language, setLanguage ] = useState(getCookieLanguage(env) ?? 'en');

    useEffect(() => {
        let cookieLanguage = getCookieLanguage(env);
        cookieLanguage = !EnableLocKeysLanguage && cookieLanguage?.toLowerCase() === 'keys' ? 'en' : cookieLanguage;

        if (cookieLanguage) {
            setLanguage(cookieLanguage);
        }
    }, [ EnableLocKeysLanguage ]);

    const updateLanguage = useCallback((languageCode: LanguageCulture) => {
        const validCode = isValidLanguageCode(languageCode);
        if (!validCode) {
            return;
        }

        setLanguage(validCode);
        setCookieLanguage(validCode, {
            env,
            shouldUpdateUserLanguage: true,
        });
    }, [ ]);

    return (
        <LocalizationContext.Provider
            value={{
                language,
                updateLanguage,
            }}
        >
            {props.children}
        </LocalizationContext.Provider>
    );
};
