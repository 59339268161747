import {
    UiDivider,
    UiText,
} from '@experiences/ui-common';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import UiProviderButton from '../../../common/UiProviderButton/UiProviderButton';
import {
    LegacySignupFormComponent,
    SimpleSignupFormComponent,
} from '../../../common/UiSignup';
import { useSignupScreenExperiment } from '../../../util/ExperimentUtil';
import useLogin from '../../../util/hooks/Login';
import Providers from '../../../util/interfaces/provider';

const useStyles = makeStyles(theme =>
    createStyles({
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            color: theme.palette.common.black,
            fontFamily: 'Poppins',
            fontWeight: 600,
            textAlign: 'center',
        },
        standardMargin: {
            marginTop: '16px',
            textAlign: 'center',
        },
        standardWidth: { width: '400px' },
        spacer: { margin: '32px 0' },
        spacerXL: { margin: '48px 0' },
    }),
);

const SignupContainer: React.FC<{ newSignupHeader?: boolean; isBusiness?: boolean; isLegacyForm?: boolean }> = ({
    newSignupHeader = false, isBusiness = false, isLegacyForm = false,
}) => {

    const classes = useStyles();
    const navigateToLogin = useLogin();

    const { formatMessage: translate } = useIntl();

    const [ collapseCondition, setCollapseCondition ] = useState(false);

    const isExperimentActive = useSignupScreenExperiment();

    return (
        <div
            className={classes.standardWidth}>
            <UiText
                className={classes.title}
                style={{ fontSize: newSignupHeader ? '32px' : '24px' }}
                data-cy="ui-signup-title">
                {translate({
                    id: isExperimentActive ? 'SIMPLE_SIGNUP_HEADER' :
                        newSignupHeader ? 'OFFERS_REVAMP_HEADER' : 'NEW_SIGNUP_HEADER',
                })}
            </UiText>
            <UiText className={classes.standardMargin}>
                {translate(
                    { id: 'ACCOUNT_ALREADY_EXISTS_SKU' },
                    {
                        SIGNIN: (
                            <Link
                                onClick={() => {
                                    navigateToLogin();
                                }}
                                data-cy="signup-goto-login"
                                tabIndex={0}
                                style={{ cursor: 'pointer' }}
                                underline="none"
                            >
                                {translate({ id: 'SIGN_IN' })}
                            </Link>
                        ),
                    },
                )}
            </UiText>
            <div className={classes.spacerXL} />
            {isLegacyForm
                ? <LegacySignupFormComponent />
                : <SimpleSignupFormComponent
                    collapseProps={{
                        collapseCondition,
                        setCollapseCondition,
                    }}
                    isRevamp
                    isBusiness={isBusiness}
                />}
            <div className={classes.flex}>
                <UiDivider className={clsx(classes.standardWidth, classes.spacerXL)} />
                <UiProviderButton
                    provider={Providers.Google}
                    showOptions={false}
                    isSignup
                    centered />
                <UiProviderButton
                    provider={Providers.Microsoft}
                    showOptions={false}
                    isSignup
                    centered />
            </div>
            <div className={classes.spacer} />
        </div>

    );
};

export default SignupContainer;
