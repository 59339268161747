import { UiDivider } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

import useIsBusiness from '../../util/hooks/useIsBusiness';
import Providers from '../../util/interfaces/provider';
import UiProviderButton from '../UiProviderButton/UiProviderButton';
import { SimpleSignupFormComponent } from './SimpleSignupFormComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        standardWidth: { width: '400px' },
        spacer: { margin: '16px 0' },
        spacerXL: { margin: '48px 0' },
        button: {
            marginTop: '16px',
            borderColor: `${theme.palette.semantic.colorBorderDeEmp} !important`,
        },
    }),
);

export const SignupOptionsComponent = ({ isRevamp = false }) => {

    const classes = useStyles();
    const isBusiness = useIsBusiness();

    return (
        <div className={classes.standardWidth}>
            <SimpleSignupFormComponent
                isBusiness={isBusiness}
                isRevamp={isRevamp} />
            <UiDivider className={clsx(classes.standardWidth, classes.spacerXL)} />
            <div className={classes.flex}>
                <UiProviderButton
                    provider={Providers.Google}
                    showOptions={false}
                    isSignup
                    centered />
                <UiProviderButton
                    provider={Providers.Microsoft}
                    showOptions={false}
                    isSignup
                    centered />
            </div>
        </div>
    );
};
