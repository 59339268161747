import 'react-app-polyfill/stable';
import './index.css';
import './register-stencil';

import {
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import { apolloMaterialUiThemeLight } from '@uipath/apollo-mui5';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { StatsigProvider } from 'statsig-react';

import App from './App';
import { LocalizationContextProvider } from './container/Localization';
import { RouterContextProvider } from './container/Router';
import IntlProvider from './locales';
import * as serviceWorker from './serviceWorker';
import {
    getStatsigClientKey,
    getStatsigUser,
} from './util/ConfigUtil';
import { getStatsigEnvironment } from './util/EnvironmentUtil';

(async () => {
    const container = document.getElementById('root');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(container!);
    root.render(
        <StatsigProvider
            sdkKey={getStatsigClientKey()}
            user={getStatsigUser()}
            waitForInitialization
            options={{
                environment: { tier: getStatsigEnvironment() },
                disableDiagnosticsLogging: true,
                disableAutoMetricsLogging: true,
            }}
        >
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={apolloMaterialUiThemeLight}>
                    <RouterContextProvider>
                        <LocalizationContextProvider>
                            <IntlProvider>
                                <App />
                            </IntlProvider>
                        </LocalizationContextProvider>
                    </RouterContextProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </StatsigProvider>,
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
