import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import type { VerificationInputProps } from 'react-verification-input';
import VerificationInput from 'react-verification-input';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            height: '64px',
            width: '472px',
        },
        character: {
            lineHeight: '50px',
            fontSize: '24px',
            fontFamily: 'Noto Sans',
            fontWeight: 600,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: '3px',
            marginLeft: '8px',
            padding: '8px',

            '&:first-child': { marginLeft: 0 },
        },
        input: { border: 'none' },
        'character--error': { border: `2px solid ${theme.palette.error.main}` },
        'character--inactive': { backgroundColor: theme.palette.background.paper },
        'character--loading': {
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.grey[500],
        },
        'character--selected': { },
    }),
);

interface IUiVerificationCodeInputPropsCustom {
    className?: string;
    label?: string;
    error?: boolean;
    loading?: boolean;
    helperText?: string;
}

type IUiVerificationCodeInputProps = VerificationInputProps & React.RefAttributes<HTMLInputElement> & IUiVerificationCodeInputPropsCustom;

const UiVerificationCodeInput: React.FC<IUiVerificationCodeInputProps> = ({
    className, label, loading, error, helperText, inputProps, ...rest
}) => {
    const classes = useStyles();

    return <div>
        <InputLabel>
            {label}
        </InputLabel>
        <VerificationInput
            {...rest}
            classNames={{
                container: clsx(className, classes.container),
                character: clsx(classes.character, {
                    [classes['character--loading']]: loading,
                    [classes['character--error']]: error,
                }),
                characterInactive: classes['character--inactive'],
                characterSelected: classes['character--selected'],
            }}
            inputProps={{
                disabled: loading,
                className: clsx('vi', classes.input),
                ...inputProps,
            }}
        />
        <FormHelperText
            error={error}
            style={{ marginTop: '8px' }}>
            {helperText}
        </FormHelperText>
    </div>;
};

export default UiVerificationCodeInput;
