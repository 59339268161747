import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import CuteOrangeRobot from '../../images/CuteOrangeRobot.png';
import BottomPageOptions from './subcomponents/BottomPageOptions';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundPosition: '0 0',
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            overflowY: 'auto',
            overflowX: 'hidden',
            flexDirection: 'column',
            position: 'relative',
        },
        textArea: {
            width: '350px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '100px 50px 24px 50px',
            minHeight: '400px',
        },
        robot: {
            margin: '35vh 700px -50vh 60px',
            width: '20px',
        },
        '@media all and (max-width: 768px)': { robot: { display: 'none' } },
    }),
);

export const UiBackgroundLogin: React.FC<{
    children?: React.ReactNode;
    className?: string;
    bottomOptions?: boolean;
    showShapes?: boolean;
    backgroundColor?: { primary: string; secondary: string };
}> = ({
    children, className, bottomOptions, backgroundColor, showShapes = false,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div
            className={`${classes.root} ${className ?? ''}`}
            style={{ background: backgroundColor?.primary ?? '' }}
        >
            {showShapes &&
                <div className={classes.robot}>
                    <img
                        src={CuteOrangeRobot}
                        alt={translate({ id: 'ORANGE_ROBOT_ALT' })}
                    />
                </div>}
            {children}
            {bottomOptions ? <BottomPageOptions /> : <></>}
        </div>
    );
};
