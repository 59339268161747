import { SeverityLevel } from '@microsoft/applicationinsights-web';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import telemetryProvider from '../telemetry';
import { getOrganizationAppOriginFromEnv } from './ConfigUtil';
import type { ISignup } from './interfaces/signup';

export async function addUserMarketplace(userData: ISignup) {
    try {
        const data = JSON.stringify({
            'email': userData.email,
            'firstName': userData.firstName,
            'lastName': userData.lastName,
            'Country': userData.country,
            'Last_Interaction__c': 'Platforms',
            'Last_Interaction_Details__c': 'Marketplace Sign Up',
        });

        const config: AxiosRequestConfig = {
            method: 'post',
            url: `${getOrganizationAppOriginFromEnv()}/portal_/api/marketo/updateLastInteraction`,
            data,
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        telemetryProvider.trackTrace({
            message: `Error occurred while updating/creating marketplace interaction ${error}`,
            severityLevel: SeverityLevel.Error,
        });
    }
}
