import React from 'react';

import usePage from './container/Page';

const App = () => {
    const PageComponent = usePage();

    return (
        <div className="App">
            <PageComponent />
        </div>
    );
};

export default App;

