import { UiSignupLayoutRevamp } from '@experiences/ui-common';
import React from 'react';

import {
    UiVerificationContainer,
    UiVerificationLayout,
} from '../../common/UiVerification';
import { VerificationFormComponent } from './subcomponents/VerificationFormComponent';

const VerificationPageComponent = () => (
    <UiSignupLayoutRevamp>
        <UiVerificationLayout
            center={<UiVerificationContainer form={<VerificationFormComponent />} />}
            withLogo={false} />
    </UiSignupLayoutRevamp>
);

export default VerificationPageComponent;
