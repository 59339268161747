import { UiText } from '@experiences/ui-common';
import Link from '@mui/material/Link';
import React from 'react';
import { useIntl } from 'react-intl';

export const CheckBoxLabel: React.FC<{ href: string[]; hrefText: string[]; label?: string }> = ({
    label,
    href,
    hrefText,
}) => {
    const { formatMessage: translate } = useIntl();

    const links: Record<number, any> = {};
    href.forEach(
        (link, index) =>
            (links[index] = (
                <Link
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer">
                    {translate({ id: hrefText[index] })}
                </Link>
            )),
    );

    return <UiText data-cy="link-check-label">
        {translate({ id: label }, links)}
    </UiText>;
};
