import {
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useLocalization } from '../container/Localization';
import {
    Features,
    useFeatureFlagValue,
} from '../util/FeatureFlagUtil';
import en from './translation-countries-en.json';

const useTranslatedCountries = () => {
    const locale = useLocalization(true);
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);

    const enCountries = useMemo(() => Object.values(en), []);
    const [ translatedCountries, setTranslatedCountries ] = useState<Record<string, string> | string[]>(enCountries);

    useEffect(() => {
        (async () => {
            if (locale === 'keys') {
                if (EnableLocKeysLanguage) {
                    const keys = Object.keys(en).reduce(
                        (accum, current) => ({
                            ...accum,
                            [current]: current,
                        }),
                        {},
                    );
                    setTranslatedCountries(keys);
                }
                return;
            }
            const localeData = (await import(`./translation-countries-${locale}.json`))?.default;
            const values: string[] = Object.values(localeData);
            const entries = enCountries.reduce((accumulator, current, index) => {
                accumulator[current] = values[index]; return accumulator;
            }, {} as Record<string, string>);
            setTranslatedCountries(entries);
        })();
    }, [ enCountries, locale, EnableLocKeysLanguage ]);

    return translatedCountries;
};

export default useTranslatedCountries;
