import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import useAuthInstance from '../../util/hooks/AuthInstance';
import { UiSocialButton } from '../UiSocialButton';

const useStyles = makeStyles((theme) =>
    createStyles({
        socialButton: {
            marginBottom: '16px',
            borderColor: `${theme.palette.semantic.colorBorderDeEmp} !important`,
        },
        singleSocialButton: {
            marginBottom: '16px',
            borderColor: `${theme.palette.semantic.colorBorderDeEmp} !important`,
        },
    }),
);

const UiProviderButton: React.FC<{
    provider: string;
    showOptions: boolean;
    index?: number;
    isSignup?: boolean;
    centered?: boolean;
}> = ({
    provider, showOptions, index, isSignup, centered,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const getAuthInstance = useAuthInstance();

    const providerCapFirst = useMemo(() => provider.substring(0, 1).toUpperCase() + provider.substring(1), [ provider ]);

    if (!provider) {
        return null;
    }

    return (
        <UiSocialButton
            key={index}
            icon={<img
                src={'https://platform-cdn.uipath.com/auth0-login/' + provider + 'Logo.svg'}
                alt={providerCapFirst} />}
            fullWidth
            className={clsx(classes.socialButton, { [classes.singleSocialButton]: !showOptions })}
            onClick={() => {
                (getAuthInstance() as any)[`authorizeWith${providerCapFirst}`](
                    isSignup,
                    showOptions,
                );
            }}
            centered={centered}
            data-cy={`authorize-with-${provider}`}
        >
            {translate({ id: `CONTINUE_WITH_${provider.toUpperCase()}` })}
        </UiSocialButton>
    );
};

export default UiProviderButton;
