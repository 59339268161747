import React from 'react';

import UiProviderButton from '../../../common/UiProviderButton/UiProviderButton';
import {
    SignupOptionsComponent,
    UiSignupContainer,
} from '../../../common/UiSignup';
import { useParams } from '../../../container/Router';
import useIsProviderInvite from '../../../util/hooks/Invite';
import { LoginProfileComponent } from '../../LoginPageComponent/LoginProfileComponent';

const InviteRightContainer = () => {
    const params = useParams();
    const isProviderInvite = useIsProviderInvite();

    return (
        <div style={{
            marginTop: 'auto',
            width: '360px',
        }}>
            <UiSignupContainer
                customTitleText="NEW_SIGNUP_HEADER"
                centered>
                {isProviderInvite ? (
                    <div style={{
                        width: '100%',
                        marginTop: '24px',
                    }}>
                        <LoginProfileComponent
                            email={params.login_hint}
                            provider={<UiProviderButton
                                provider={params.login_provider}
                                showOptions={false}
                                isSignup
                                centered />}
                            isSignup
                        />
                    </div>
                ) : (
                    <SignupOptionsComponent isRevamp />
                )}
            </UiSignupContainer>
        </div>
    );
};

export default InviteRightContainer;
