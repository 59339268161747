import React, { useEffect } from 'react';

import { useFreeSku } from '../util/hooks/FreeSku';
import { useRegister } from '../util/hooks/Register';

export const FreeSkuRegister = () => {
    const register = useRegister();
    const {
        isFreeSkuRegister, retryUrl,
    } = useFreeSku();

    useEffect(() => {
        if (isFreeSkuRegister) {
            register({
                subscriptionPlan: 'automationkit',
                retryUrl,
            });
        }
    }, [ isFreeSkuRegister, retryUrl, register ]);

    return <></>;
};
