import { useCallback } from 'react';

import { useRouter } from '../../container/Router';
import { logClick } from '../../telemetry';
import { useFreeSku } from './FreeSku';

export default function useLogin() {
    const nextRoute = useRouter();

    const {
        isFreeSkuCallback, freeSkuSignInUrl,
    } = useFreeSku();

    const navigateToLogin = useCallback(
        () => {
            logClick('SignUpPage', true, 'SIGN_IN');

            return isFreeSkuCallback ? window.location.assign(freeSkuSignInUrl) :
                nextRoute({ type: 'login' });
        },
        [ freeSkuSignInUrl, isFreeSkuCallback, nextRoute ],
    );

    return navigateToLogin;
}
