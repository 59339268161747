import { useCallback } from 'react';

import { useLocalization } from '../../container/Localization';
import type { IUiLinkMap } from '../interfaces/link';

export const useLocalizedLinks = () => {
    const language = useLocalization(true);

    const getLocalizedLink = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (linkMap: IUiLinkMap) => linkMap.get(language) ?? linkMap.get('en')!,
        [ language ],
    );

    return getLocalizedLink;
};
