import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    createIntl,
    createIntlCache,
    RawIntlProvider,
} from 'react-intl';

import { useLocalization } from '../container/Localization';
import {
    Features,
    useFeatureFlagValue,
} from '../util/FeatureFlagUtil';
import en from './translation-auth-en.json';

const cache = createIntlCache();

export const IntlProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const locale = useLocalization(true);
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);

    const [ messages, setMessages ] = useState<Record<string, string>>(en);

    useEffect(() => {
        (async () => {
            if (locale?.toLowerCase() === 'keys') {
                if (EnableLocKeysLanguage) {
                    const keys = Object.keys(en).reduce((accum, current) => ({
                        ...accum,
                        [current]: current,
                    }), {});
                    setMessages(keys);
                }
                return;
            }
            const localeData = await import(`./translation-auth-${locale}.json`);
            setMessages({
                ...en,
                ...localeData,
            });
        })();
    }, [ locale, EnableLocKeysLanguage ]);

    const intlShape = useMemo(() => {
        if (!messages) {
            return null;
        }

        return createIntl(
            {
                locale: ((locale as string)?.toLowerCase() === 'keys' ? 'en' : locale),
                defaultLocale: 'en',
                messages,
            },
            cache,
        );
    }, [ locale, messages ]);

    return intlShape ? <RawIntlProvider value={intlShape}>
        {children}
    </RawIntlProvider> : null;
};

export default IntlProvider;

export { default as en } from './translation-auth-en.json';
