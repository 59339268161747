import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import type { IExtraParams } from '../util/interfaces/extraparams';
import type { IParams } from '../util/interfaces/params';
import { getParams } from '../util/RouteUtil';

export const RouterContext = React.createContext<{
    params: IParams;
    nextRoute: (_?: IExtraParams) => void;
}>({
            params: {} as IParams,
            nextRoute: () => {},
        });

export const useRouter = () => {
    const { nextRoute } = useContext(RouterContext);
    return nextRoute;
};

export const useParams = () => {
    const { params } = useContext(RouterContext);
    return params;
};

export const RouterContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
    const [ params, setParams ] = useState<IParams>({} as any);

    const setParamsLocal = useCallback(
        (search?: string) => {
            setParams(getParams(search));
        },
        [ setParams ],
    );

    const handlePopState = useCallback(() => {
        setParamsLocal();
    }, [ setParamsLocal ]);

    useEffect(() => {
        handlePopState();
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [ handlePopState, setParamsLocal ]);

    const nextRoute = useCallback(
        (extraParams?: IExtraParams) => {
            const url = new URL(window.location.href);
            extraParams && Object.entries(extraParams).forEach(([ key, value ]) => url.searchParams.set(key, value));
            setParamsLocal(url.search);
            window.history.pushState({}, '', url.toString());
        },
        [ setParamsLocal ],
    );

    return (
        <RouterContext.Provider
            value={{
                params,
                nextRoute,
            }}
        >
            {props.children}
        </RouterContext.Provider>
    );
};
