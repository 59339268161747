import { useMemo } from 'react';

import { useParams } from '../../container/Router';
import { getOrganizationAppOriginFromEnv } from '../ConfigUtil';

export function useFreeSku() {
    const {
        retryUrl, platform_name, licenseEdition, free_sku_signin,
    } = useParams();

    return useMemo(() => {
        const isInitialFreeSkuRegister = !!licenseEdition; // true when user initially lands on free sku sign up
        const isFreeSkuCallback = platform_name?.includes('Automation Kit'); // true when user lands back in Auth0
        const isSecondaryFreeSkuRegister = !!free_sku_signin; // true when user clicks sign in from the free sku sign up page

        return {
            retryUrl,
            freeSkuSignInUrl: `${getOrganizationAppOriginFromEnv()}${retryUrl}&free_sku_signin=true`,
            isFreeSkuCallback,
            isFreeSkuRegister: isInitialFreeSkuRegister && !isFreeSkuCallback && !isSecondaryFreeSkuRegister,
            isSecondaryFreeSkuRegister,
        };
    }, [ licenseEdition, retryUrl, platform_name, free_sku_signin ]);
}
