import CheckIcon from '@mui/icons-material/Check';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import palette from '../../theme/palette';

const useStyles = makeStyles(() =>
    createStyles({
        list: {
            marginLeft: '-3px',
            boxShadow: 'none',
            background: 'unset',
        },
        listItem: {
            padding: 0,
            margin: '8px 0',
        },
        listItemIcon: {
            transform: 'scale(0.667)',
            margin: '0px 8px 0 0',
            color: palette.light.list.body,
            minWidth: 'auto',
            alignSelf: 'flex-start',
        },
        listItemText: {
            fontSize: '14px',
            lineHeight: '16px',
            color: palette.light.list.body,
        },
    }),
);

export const UiList: React.FC<{
    color?: string;
    colorListItemCheckmark?: string;
    items: any[];
}> = ({
    color, colorListItemCheckmark, items,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <List
            dense
            disablePadding
            className={classes.list}>
            {items.map((item, i) => (
                <ListItem
                    key={i}
                    disableGutters
                    className={classes.listItem}>
                    <ListItemIcon
                        className={classes.listItemIcon}
                        style={{ color: colorListItemCheckmark ?? palette.light.list.body }}>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={translate({ id: item })}
                        primaryTypographyProps={{
                            className: classes.listItemText,
                            style: { color: color ?? palette.light.list.body },
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default UiList;
