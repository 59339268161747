import axios from 'axios';

import { getOrganizationAppOriginFromEnv } from '../util/ConfigUtil';

export function sendAuthSignupRequest(payload: { email: string; password: string }, client: string) {
    return axios.post(`${getOrganizationAppOriginFromEnv()}/portal_/api/auth/user/signup`, {
        email: payload.email,
        password: payload.password,
        clientId: client,
    }, { headers: { 'Content-Type': 'application/json' } });
}
