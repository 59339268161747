import * as colors from '@mui/material/colors';

const white = '#FFFFFF';

export default {
    primary: {
        contrastText: white,
        main: '#0067df',
    },
    secondary: {
        contrastText: white,
        main: '#ff6900',
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: '#F4F6F8',
        paper: white,
    },
    backgroundGreen: {
        primary: '#EEFFE5',
        secondary: '#539D2F',
    },
    backgroundOrange: {
        primary: '#FEE3DC',
        secondary: '#FC7753',
    },
    backgroundBlue: {
        primary: '#DDE7F8',
        secondary: '#0056BA',
    },
    text: {
        primary: '#343B41',
        secondary: '#676E74',
        link: colors.blue[600],
    },
    divider: colors.grey[200],

    light: {
        title: {
            primary: '#3A464F',
            secondary: '#FA4616',
            tertiary: '#0067DF',
        },
        list: {
            header: '#343B41',
            body: '#676E74',
        },
        shape: '#DAE8FA',
        shapePink: '#FFF7F5',
        subtitle: '#343B41',
        link: '#1976D2',
        background: {
            primary: '#F7F7F7',
            secondary: '#BDBDBD',
        },
        backgroundNew: {
            primary: '#F1F6F8',
            secondary: '#BDBDBD',
        },
        backgroundWhite: {
            primary: '#FFFFFF',
            secondary: '#FFFFFF',
        },
    },

    dark: {
        title: {
            primary: '#D3D4D6',
            secondary: '#FA4616',
        },
        list: {
            header: '#ECEDEE',
            body: '#D3D4D6',
        },
        shape: '#E1E2E4',
        subtitle: '#FFFFFF',
        link: '#4E94E8',
        background: {
            primary: '#1D1D1E',
            secondary: '#4F4F4F',
        },
    },
};
