import {
    useCallback,
    useMemo,
} from 'react';

import { useParams } from '../../container/Router';

export const PlatformName = {
    UiPlatform: 'UiPath Platform',
    UiAutomationCloud: 'UiPath Automation Cloud',
    UiEnterprise: 'UiPath Automation Cloud for enterprise',
    UiCommunity: 'UiPath Automation Cloud for community',
    UiForum: 'UiPath Forum',
    UiAutomationHub: 'UiPath Automation Hub',
    AutomationHub: 'Automation Hub',
    UiMarketPlace: 'UiPath Marketplace',
    UiFree: 'UiPath Automation Cloud Free',
};

export const useSignupType = () => {
    const params = useParams();

    return {
        signupType: useMemo(() => params.platform_name, [ params.platform_name ]),
        isSignupType: useCallback((type: string) => params.platform_name === type, [ params.platform_name ]),
    };
};
