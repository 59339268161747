import { UiText } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { useParams } from '../../container/Router';
import useLogin from '../../util/hooks/Login';
import { useRegister } from '../../util/hooks/Register';
import type { IAuthErrorDetail } from '../UiSignup/AuthError';

const useStyles = makeStyles(() =>
    createStyles({
        link: {
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        actionErrorText: { fontSize: '12px' },
        enterpriseError: { marginTop: '12px' },
    }),
);

const UiActionErrorMessage: React.FC<{ error: IAuthErrorDetail }> = ({ error }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigateToLogin = useLogin();
    const params = useParams();
    const register = useRegister();

    const automationCloudLink = useCallback((str: React.ReactNode[]) =>
        <UiText
            display="inline"
            className={clsx(classes.link, classes.actionErrorText)}
            onClick={() => register({ subscriptionPlan: 'community' })}>
            {str}
        </UiText>
    , [ classes, register ]);

    return (
        <>
            {error.actionMessage ?

                translate(
                    { id: error.message },
                    {
                        0: (
                            <span
                                role='link'
                                tabIndex={0}
                                key="auth-error-message"
                                data-cy={error.dataCy}
                                onKeyDown={navigateToLogin}
                                onClick={navigateToLogin}
                                className={classes.link}
                            >
                                {translate({ id: error.actionMessage })}
                            </span>
                        ),
                    },
                ) :
                translate({ id: error.message })}
            {
                params.platform_name === 'UiPath Automation Cloud for enterprise' && error.message === 'ERROR_BUSINESS_EMAIL' &&
                <div className={classes.enterpriseError}>
                    <FormattedMessage
                        id="BUSINESS_EMAIL_ENTERPRISE_ERROR"
                        values={{ a: (str: React.ReactNode[]) => automationCloudLink(str) }}
                    />
                </div>
            }
        </>
    );
};

export default UiActionErrorMessage;
