import type {
    IDirectBuyData,
    IOrderSummary,
    IProductPrices,
} from '@experiences/ecommerce';

export function getTotalPrice(productPricesDiscounts: IProductPrices[], directBuyData: IDirectBuyData, currency: string) {
    let price = 0;
    productPricesDiscounts.forEach(product => {
        const productQuantity = directBuyData.productQuantities[product.code];
        if (productQuantity) {
            price += (productQuantity * product.prices[`${currency}`]);
        }
    });
    return price;
}

export function buildOrderSummary(
    prices: IProductPrices[] | undefined,
    directBuyData: IDirectBuyData | undefined,
) {
    if (prices) {
        const currency = directBuyData?.currency ?? 'USD';
        const planType = directBuyData?.commitmentType;
        const filteredPrices = prices.filter(price => price.planType === planType);
        let orderSummary;

        if (directBuyData) {
            const price = getTotalPrice(filteredPrices, directBuyData, currency);
            orderSummary = {
                price,
                currency,
            } as IOrderSummary;
        }
        return orderSummary;
    }
}

export function buildDirectBuyData(params:
{
    db_commitment_type: string;
    db_currency: string;
    db_product_quantities: string;
}) {
    const data: IDirectBuyData = {
        productQuantities: {},
        commitmentType: params.db_commitment_type || 'ANNUAL_SUB_MONTHLY_PAY',
        currency: params.db_currency || 'USD',
    };

    if (params.db_product_quantities) {
        const decoded = decodeURIComponent(params.db_product_quantities);
        const quantitiesObject = JSON.parse(decoded);

        data.productQuantities = quantitiesObject;
    }

    return data;
}
