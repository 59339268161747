import { Languages } from '@uipath/portal-shell-util';
import { useMemo } from 'react';

import {
    Features,
    useFeatureFlagValue,
} from './FeatureFlagUtil';

export const languageCodes = [ 'en', 'ja', 'de', 'fr', 'es', 'pt-BR', 'es-MX', 'ko', 'zh-CN', 'zh-TW', 'pt', 'tr', 'ru', 'keys' ] as const;
export type LanguageCulture = typeof languageCodes[number];

export function isValidLanguageCode(languageCode: string | null | undefined) {
    return languageCodes.find(language => language.toLowerCase() === languageCode?.toLowerCase());
}

export const getBrowserLanguage = () =>
    isValidLanguageCode(navigator.language) ?? isValidLanguageCode(navigator.language.substring(0, 2)) ?? 'en';

export const useSupportedLanguages = () => {
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);

    return useMemo(() => {
        let languageList = Object.entries(Languages);
        if (!EnableLocKeysLanguage) {
            languageList = languageList.filter(lang => lang[0] !== 'keys');
        }
        return languageList.map(([ id, text ]) => ({
            id,
            text,
        }));
    }, [ EnableLocKeysLanguage ]);
};
