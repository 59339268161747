import FormControl from '@mui/material/FormControl';
import type { InputProps } from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import React, {
    useCallback,
    useMemo,
} from 'react';
import type {
    Control,
    FieldPath,
    FieldValues,
} from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface IUiSelectProps<T extends FieldValues> extends InputProps {
    control: Control<T>;
    name: FieldPath<T>;
    inputLabel: string;
    options: { [k: string]: string } | any[];
    dataCy: string;
    useValues?: boolean;
}

export const UiSelect = <T extends FieldValues>({
    control,
    name,
    inputLabel,
    options,
    dataCy,
    error,
    disabled,
    className,
    style,
    required,
    useValues,
}: IUiSelectProps<T>) => {
    const { formatMessage: translate } = useIntl();

    const mapEnglishToTranslated = useCallback(
        (englishToI18nCode: { [k: string]: string }) => {
            const englishToTranslationMap: { [k: string]: string } = {};
            Object.entries(englishToI18nCode).forEach(([ key, value ]) => {
                englishToTranslationMap[key] = useValues ? value : translate({ id: value });
            });
            return englishToTranslationMap;
        },
        [ translate, useValues ],
    );

    const optionsArray = useMemo(
        () =>
            Array.isArray(options)
                ? options.map((value) => ({
                    key: value,
                    value,
                }))
                : Object.entries(mapEnglishToTranslated(options)).map(([ key, value ]) => ({
                    key,
                    value,
                })),
        [ options, mapEnglishToTranslated ],
    );

    return (
        <Controller
            name={name}
            rules={{ required }}
            control={control}
            render={({ field }) => (
                <FormControl className={className}>
                    <InputLabel htmlFor={field.name}>
                        {inputLabel}
                    </InputLabel>
                    <Select
                        {...field}
                        defaultValue=""
                        style={style}
                        native
                        label={inputLabel}
                        inputProps={{
                            id: field.name,
                            style: { height: '1.5em' },
                        }}
                        variant="outlined"
                        error={error}
                        disabled={disabled}
                        fullWidth
                        data-cy={dataCy}
                    >
                        <option
                            aria-label="None"
                            hidden
                            value="" />
                        {optionsArray.map((option, i) => (
                            <option
                                key={i}
                                value={option.key}>
                                {option.value}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            )}

        />
    );
};

export default UiSelect;
