import axios from 'axios';

import { getOrganizationAppOriginFromEnv } from './ConfigUtil';

export async function validateEmail(email: string, failSecure = false) {
    try {
        const response = await axios.get<boolean>(
            `${getOrganizationAppOriginFromEnv({ useServer: true })}/portal_/api/auth/Email/validate?email=${email}`,
        );
        return response.data;
    } catch (error) {
        return !failSecure;
    }
}
