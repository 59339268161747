import {
    UiDivider,
    UiText,
} from '@experiences/ui-common';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import BottomPageOptions from '../../common/UiBackground/subcomponents/BottomPageOptions';
import UiProviderButton from '../../common/UiProviderButton/UiProviderButton';
import { SimpleSignupFormComponent } from '../../common/UiSignup';
import { useFreeSku } from '../../util/hooks/FreeSku';
import Providers from '../../util/interfaces/provider';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            paddingTop: '40px',
        },
        rightContainer: {
            flexGrow: 2,
            overflow: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '20vh',
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontSize: '32px',
            textAlign: 'center',
            lineHeight: 'unset',
        },
        standardMargin: {
            marginTop: '16px',
            textAlign: 'center',
        },
        standardWidth: { width: '360px' },
        spacer: { margin: '32px 0' },
    }),
);

const FreeSkuRightContainer = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { freeSkuSignInUrl } = useFreeSku();

    const [ collapseCondition, setCollapseCondition ] = useState(false);

    return (
        <div className={clsx(classes.container, classes.rightContainer)}>
            <div className={classes.standardWidth}>
                <UiText className={classes.title}>
                    {translate({ id: 'FREE_SKU_SIGNUP_TITLE' })}
                </UiText>
                <UiText className={classes.standardMargin}>
                    {translate(
                        { id: 'ACCOUNT_ALREADY_EXISTS_SKU' },
                        {
                            SIGNIN: (
                                <Link
                                    key="link"
                                    href={freeSkuSignInUrl}
                                    underline="none">
                                    {translate({ id: 'SIGN_IN' })}
                                </Link>
                            ),
                        },
                    )}
                </UiText>
                <SimpleSignupFormComponent
                    collapseProps={{
                        collapseCondition,
                        setCollapseCondition,
                    }}
                    isBusiness />
                <Collapse in={!collapseCondition}>
                    <div className={classes.flex}>
                        <UiDivider className={clsx(classes.standardWidth, classes.spacer)} />
                        <UiProviderButton
                            provider={Providers.Google}
                            showOptions={false}
                            centered />
                        <UiProviderButton
                            provider={Providers.Microsoft}
                            showOptions={false}
                            centered />
                    </div>
                </Collapse>
                <div className={classes.spacer} />
            </div>
            <BottomPageOptions />
        </div>
    );
};

export default FreeSkuRightContainer ;
