import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import type {
    Control,
    FieldPath,
    FieldValues,
} from 'react-hook-form';
import {
    Controller,
    useFormState,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        helperText: { marginRight: 'unset' },
        helperTextContainer: {
            fontSize: '0.75rem',
            fontWeight: 400,
            letterSpacing: '0.03333em',
            display: 'inline-flex',
            alignItems: 'center',
        },
        helperTextIcon: {
            fontSize: 'unset',
            marginRight: '4px',
        },
    }),
);

interface ICustomValidation {
    rules: any;
    error: boolean;
    helperText?: string;
}

const HelperText: React.FC<{ error: boolean; touched: boolean; errorMessage: string }> = ({
    error,
    touched,
    errorMessage,
}) => {
    const classes = useStyles();

    if (!touched) {
        return null;
    }

    return (
        <div style={{ color: error ? 'red' : 'green' }}>
            {error ? (
                <CloseIcon className={classes.helperTextIcon} />
            ) : (
                <CheckIcon className={classes.helperTextIcon} />
            )}
            <span className={classes.helperTextContainer}>
                {errorMessage}
            </span>
        </div>
    );
};

interface IUiPasswordProps<T extends FieldValues> {
    control: Control<T>;
    name: FieldPath<T>;
    className?: string;
    useDefaultValidation?: boolean;
    customValidation?: ICustomValidation;
    dataCy?: string;
}

const UiPassword = <T extends FieldValues>({
    control, name, className, useDefaultValidation, customValidation, dataCy,
}: IUiPasswordProps<T>) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ showPassword, setShowPassword ] = useState(false);
    const [ touched, setTouched ] = useState(control.getFieldState(name).isTouched);

    const handleClickShowPassword = useCallback(() => {
        setShowPassword(!showPassword);
    }, [ showPassword ]);

    const handleMouseDownPassword = useCallback((event: any) => {
        event.preventDefault();
    }, []);

    const { errors }: { errors: any } = useFormState({ control });

    const strongPasswordError = !!errors?.password?.types?.hasStrongPassword;
    const minimumCharactersError = !!errors?.password?.types?.minLength || !!errors?.password?.types?.required;
    const upperAndLowerError = !!errors?.password?.types?.hasUpperAndLower;
    const specialCharacterError = !!errors?.password?.types?.hasSpecialCharacter;
    const atleastOneDigitError = !!errors?.password?.types?.hasAtleastOneDigit;

    const PasswordHelperText = useMemo(() => (
        <>
            <HelperText
                touched={touched}
                error={strongPasswordError}
                errorMessage={translate({ id: `PASSWORD_${strongPasswordError || !touched ? 'WEAK' : 'STRONG'}` })}
            />
            <HelperText
                touched={touched}
                error={minimumCharactersError}
                errorMessage={translate({ id: 'MIN_CHARACTERS' })}
            />
            <HelperText
                touched={touched}
                error={upperAndLowerError}
                errorMessage={translate({ id: 'AT_LEAST_MULTIPLE_CASES' })}
            />
            <HelperText
                touched={touched}
                error={specialCharacterError}
                errorMessage={translate({ id: 'AT_LEAST_ONE_SPECIAL_CHAR' })}
            />
            <HelperText
                touched={touched}
                error={atleastOneDigitError}
                errorMessage={translate({ id: 'AT_LEAST_ONE_NUMBER' })}
            />
        </>
    ), [
        touched,
        strongPasswordError,
        translate,
        minimumCharactersError,
        upperAndLowerError,
        specialCharacterError,
        atleastOneDigitError,
    ]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <TextField
                    {...field}
                    type={showPassword ? 'text' : 'password'}
                    label={translate({ id: 'PASSWORD' })}
                    InputLabelProps={{ id: 'passwordLabel' }}
                    variant="outlined"
                    required={useDefaultValidation ? true : (customValidation?.rules?.required ?? false)}
                    error={useDefaultValidation ? !!errors?.password?.ref : customValidation?.error ?? undefined}
                    helperText={
                        useDefaultValidation ? (touched ? PasswordHelperText : '') : customValidation?.helperText ?? ''
                    }
                    className={className}
                    FormHelperTextProps={{ className: classes.helperText }}
                    fullWidth
                    onChange={(...args) => (setTouched(true), field.onChange(...args))}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={translate({ id: 'TOGGLE_PASSWORD_VISIBILITY' })}
                                    edge="end"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        'data-cy': dataCy,
                        'aria-labelledby': 'passwordLabel',
                    }}
                />
            )}
            rules={
                useDefaultValidation
                    ? {
                        required: true,
                        minLength: 8,
                        maxLength: 256,
                        validate: {
                            hasStrongPassword: value =>
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^%*?&"'()+,\-./:;<=>?[\\\]_`{}~])[\w\W]{8,}$/.test(value),
                            hasUpperAndLower: value => /^(?=.*[a-z])(?=.*[A-Z])/.test(value),
                            hasSpecialCharacter: value => /(?=.*[@$!#^%*?&"'()+,\-./:;<=>?[\\\]_`{}~])/.test(value),
                            hasAtleastOneDigit: value => /(?=.*\d)/.test(value),
                        },
                    }
                    : customValidation?.rules ?? undefined
            }
        />
    );
};

export default UiPassword;
